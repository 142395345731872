/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Container, FormControl } from 'react-bootstrap';

// Components
import UsersTab from './UsersTab';
import PendingTab from './PendingTab';
import { objectCleaner } from 'utils/objectCleaner';
import AddUser from './components/Modals/AddUser/AddUser';
import ConditionalRender from 'components/ConditionalRender';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { getUsers, getUsersState } from 'reducers/usersReducer';
import { checkStringPermission } from 'helpers/filteredPermissions';

// Assets
import styles from './index.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';

// Main component
const Users = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const [data, setData] = useState([]);

  const [selectTab, setSelectTab] = useState('Users');

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    sort_by: '',
    filter_status: 'approved',
    order_by: 'user_profile.status',
  });

  const [showAdd, setShowAdd] = useState(false);

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getUsers(params));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          id: dd?.id,
          userID: dd?.id,
          email: dd?.attributes?.email,
          position: dd?.attributes?.role,
          status: dd?.attributes?.status,
          name: dd?.attributes?.full_name,
          image: dd?.attributes?.profile_pic,
          dateAdded: dd?.attributes?.created_at,
        };
      });

      setData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value, page: 1 }));

    const newObj = {
      page: 1,
      sort_by: value,
      q: filter?.search,
      per_page: filter?.per_page,
      order_by: filter?.order_by,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        sort_by: filter?.sort_by,
        order_by: filter?.order_by,
        per_page: filter?.per_page,
        filter_status: filter?.filter_status,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handleUsers = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      order_by: filter?.order_by,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.search,
      sort_by: filter?.sort_by,
      order_by: filter?.order_by,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const changeTab = (tab) => {
    // eslint-disable-next-line prefer-const
    let newObj = {
      q: filter?.search,
      filter_status: '',
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      order_by: filter?.order_by,
    };

    if (tab === 'Users') {
      setFilter((prev) => ({ ...prev, filter_status: 'approved' }));
      newObj.filter_status = 'approved';
    }

    if (tab === 'Pending Users') {
      setFilter((prev) => ({ ...prev, filter_status: 'pending_declined' }));
      newObj.filter_status = 'pending_declined';
    }

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  useEffect(() => {
    handleUsers();
  }, []);

  const canUpdate = checkStringPermission('can update user');
  const canDelete = checkStringPermission('can delete user');
  const canApprove = checkStringPermission('can approve user');
  const canCreate = checkStringPermission('can create user');

  return (
    <PrivateLayout pageTitle='Users'>
      <div className={styles?.users}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          changeTab('Users');
                          setSelectTab('Users');
                        }}
                        className={`${selectTab === 'Users' ? styles?.active : ''}`}
                      >
                        Users
                      </Button>
                    </div>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          changeTab('Pending Users');
                          setSelectTab('Pending Users');
                        }}
                        className={`${selectTab === 'Pending Users' ? styles?.active : ''}`}
                      >
                        Pending Users
                      </Button>
                    </div>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    {selectTab === 'Users' && (
                      <div className={styles?.selectContainer}>
                        <Form.Select
                          name='sort_by'
                          onChange={handleSort}
                          className={styles?.select}
                          value={filter?.sort_by}
                        >
                          <option value='' hidden>
                            Sort By
                          </option>
                          <option value='ASC'>Active</option>
                          <option value='DESC'>Inactive</option>
                        </Form.Select>
                      </div>
                    )}

                    {selectTab === 'Pending Users' && (
                      <div className={styles?.selectContainer}>
                        <Form.Select
                          name='sort_by'
                          onChange={handleSort}
                          className={styles?.select}
                          value={filter?.sort_by}
                        >
                          <option value='' hidden>
                            Sort By
                          </option>
                          <option value='pending'>Pending</option>
                          <option value='declined'>Declined</option>
                        </Form.Select>
                      </div>
                    )}

                    <ConditionalRender
                      condition={canCreate}
                      renderIf={
                        <Button
                          className={styles?.processItem}
                          onClick={() => {
                            setShowAdd(true);
                          }}
                        >
                          <CirclePlus />
                          Add New User
                        </Button>
                      }
                    />
                  </div>
                </div>

                <div className={styles?.body}>
                  <ConditionalRender
                    condition={selectTab === 'Users'}
                    renderIf={
                      <UsersTab
                        data={data}
                        canUpdate={canUpdate}
                        setFilter={setFilter}
                        handleUsers={handleUsers}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />

                  <ConditionalRender
                    condition={selectTab === 'Pending Users'}
                    renderIf={
                      <PendingTab
                        data={data}
                        setFilter={setFilter}
                        canApprove={canApprove}
                        handleUsers={handleUsers}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />
                </div>
              </div>

              <AddUser handleUsers={handleUsers} show={showAdd} setShowAdd={setShowAdd} />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Users;
