/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  addRole,
  getRoles,
  deleteRole,
  updateRoles,
  addPermission,
  removePermission,
} from './thunks/rolesThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  roles: [],
  meta: null,
  logs: initialLog,
  uploadLogs: initialLog,
  addLogs: initialLog,
  addPermissionLogs: initialLog,
  removePermissionLogs: initialLog,
  deleteLogs: initialLog,
  filterState: {
    q: '',
    page: 1,
    per_page: 5,
    sort_by: '',
  },
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      state.filterState = {
        ...state.filterState,
        ...action?.payload,
      };
    },
    resetDelete: (state, action) => {
      state.deleteLogs = initialLog;
    },
  },
  extraReducers: {
    [getRoles.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.roles = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getRoles.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getRoles.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [updateRoles.fulfilled]: (state, action) => {
      state.uploadLogs.isSuccess = true;
      state.uploadLogs.isLoading = false;
    },
    [updateRoles.pending]: (state) => {
      state.uploadLogs.error = '';
      state.uploadLogs.isFailed = false;
      state.uploadLogs.isLoading = true;
      state.uploadLogs.isSuccess = false;
    },
    [updateRoles.rejected]: (state, action) => {
      state.uploadLogs.isFailed = true;
      state.uploadLogs.isLoading = false;
      state.uploadLogs.error = action?.payload?.errors[0]?.detail;
    },
    [addRole.fulfilled]: (state, action) => {
      state.addLogs.isSuccess = true;
      state.addLogs.isLoading = false;
    },
    [addRole.pending]: (state) => {
      state.addLogs.error = '';
      state.addLogs.isFailed = false;
      state.addLogs.isLoading = true;
      state.addLogs.isSuccess = false;
    },
    [addRole.rejected]: (state, action) => {
      state.addLogs.isFailed = true;
      state.addLogs.isLoading = false;
      state.addLogs.error = action?.payload?.errors[0]?.detail;
    },
    [addPermission.fulfilled]: (state, action) => {
      state.addPermissionLogs.isSuccess = true;
      state.addPermissionLogs.isLoading = false;
    },
    [addPermission.pending]: (state) => {
      state.addPermissionLogs.error = '';
      state.addPermissionLogs.isFailed = false;
      state.addPermissionLogs.isLoading = true;
      state.addPermissionLogs.isSuccess = false;
    },
    [addPermission.rejected]: (state, action) => {
      state.addPermissionLogs.isFailed = true;
      state.addPermissionLogs.isLoading = false;
      state.addPermissionLogs.error = action?.payload?.errors[0]?.detail;
    },
    [removePermission.fulfilled]: (state, action) => {
      state.removePermissionLogs.isSuccess = true;
      state.removePermissionLogs.isLoading = false;
    },
    [removePermission.pending]: (state) => {
      state.removePermissionLogs.error = '';
      state.removePermissionLogs.isFailed = false;
      state.removePermissionLogs.isLoading = true;
      state.removePermissionLogs.isSuccess = false;
    },
    [removePermission.rejected]: (state, action) => {
      state.removePermissionLogs.isFailed = true;
      state.removePermissionLogs.isLoading = false;
      state.removePermissionLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteRole.pending]: (state, action) => {
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deleteRole.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      if (action?.payload?.errors && action?.payload?.errors.length > 0) {
        state.deleteLogs.error = action?.payload?.errors[0]?.detail;
      } else {
        state.deleteLogs.error = action?.payload?.message?.detail;
      }
    },
  },
});

export const { actions: rolesActions, reducer: rolesReducer } = slice;

export const { updateFilter, resetDelete } = rolesActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.roles || initialState;

export const selectRoles = createSelector([selectDomain], (rolesState) => rolesState);

/* --------------------------------------------------------------------------------- */

export { addPermission, deleteRole, removePermission, addRole, getRoles, updateRoles };
