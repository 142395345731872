/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Row,
  Col,
  Form,
  Image,
  Table,
  Modal,
  Button,
  Container,
  FormControl,
} from 'react-bootstrap';

import './Modal.scss';

const ModalView = ({ show, handleClose, data, title }) => {
  console.log('data', data);

  return (
    <Modal show={show} onHide={handleClose} centered className='inventoryModal'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <div className='image'>
            <Image src={data?.image} />
          </div>

          <Row className='w-100'>
            <Col>
              <div className='group'>
                <p>
                  Item Code <span>{data?.itemCode}</span>
                </p>
              </div>

              <div className='group'>
                <p>
                  Item Name <span>{data?.itemName}</span>
                </p>
              </div>

              <div className='group'>
                <p>
                  Date Requested <span>{data?.dateRequested}</span>
                </p>
              </div>

              <div className='group'>
                <p>
                  Borrowed by <span>{data?.borrowedBy}</span>
                </p>
              </div>
            </Col>
            <Col>
              <div className='group'>
                <p>
                  Type of Transaction <span>{data?.typeOfTransaction}</span>
                </p>
              </div>

              <div className='group'>
                <p>
                  {/* Approver <span>{data?.approver}</span> */}
                </p>
              </div>

              <div className='group'>
                <p>
                  Stock Needed <span>{data?.stockNeeded}</span>
                </p>
              </div>

              <div className='group'>
                <p>
                  Status{' '}
                  <span className={`${data?.status === 'Completed' ? 'Completed' : 'Pending'}`}>
                    {data?.status}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalView;
