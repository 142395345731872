/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { useState, useEffect } from 'react';

const SelectUser = ({ data, onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const params = {
      per_page: 10000,
      filter_status: 'approved',
    };

    const getUsers = async () => {
      try {
        axios.get('api/users', { params: params }).then((response) => {
          const newData = response?.data?.data.map((dd) => {
            return {
              ...dd,
              value: dd?.id,
              label: `${dd?.attributes?.first_name} ${dd?.attributes?.last_name}`,
            };
          });

          setOptions(newData);
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getUsers();
  }, []);

  return <Select options={options} styles={stylesOptions} value={data} onChange={onChange} />;
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    height: '38px',
    fontWeight: 400,
    fontSize: '10px',
    borderRadius: '19px',
    background: '#F3F3F3',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '10px',
    background: '#F3F3F3',
  }),
};

export default SelectUser;
