// import core & vendor packages below
import { Helmet, HelmetData } from 'react-helmet-async';

// main component
const ReactHelmet = (props) => {
  const { title } = props;
  const helmetData = new HelmetData({});

  return (
    <Helmet helmetData={helmetData}>
      <title>{title}</title>
    </Helmet>
  );
};

export default ReactHelmet;
