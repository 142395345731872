/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getPermissions } from './thunks/permissionsThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  logs: initialLog,
};

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: {
    [getPermissions.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getPermissions.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getPermissions.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: permissionsActions, reducer: permissionsReducer } = slice;

export const { updateUserinfo } = permissionsActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.permissions || initialState;

export const selectPermissions = createSelector(
  [selectDomain],
  (permissionsState) => permissionsState,
);

/* --------------------------------------------------------------------------------- */

export { getPermissions };
