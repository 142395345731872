/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getApprovers = createAsyncThunk(
  'getApprovers',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `/api/approvers`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateApprover = createAsyncThunk(
  'updateApprover',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      data: params,
      url: `/api/approvers/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteApprover = createAsyncThunk(
  'deleteApprover',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `/api/approvers/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const addApprover = createAsyncThunk('addApprover', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `/api/approvers`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
