/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { sampleGetAPI, samplePostAPI, samplePutAPI, sampleDeleteAPI } from './thunks/sampleThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  submitLogs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'sample',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [sampleGetAPI.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [sampleGetAPI.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [sampleGetAPI.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [samplePostAPI.fulfilled]: (state, action) => {
      state.submitLogs.isSuccess = true;
      state.submitLogs.isLoading = false;
    },
    [samplePostAPI.pending]: (state) => {
      state.submitLogs.error = '';
      state.submitLogs.isFailed = false;
      state.submitLogs.isLoading = true;
      state.submitLogs.isSuccess = false;
    },
    [samplePostAPI.rejected]: (state, action) => {
      state.submitLogs.isFailed = true;
      state.submitLogs.isLoading = false;
      state.submitLogs.error = action?.payload?.errors[0]?.detail;
    },
    [samplePutAPI.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [samplePutAPI.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [samplePutAPI.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [sampleDeleteAPI.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [sampleDeleteAPI.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [sampleDeleteAPI.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: sampleActions, reducer: sampleReducer } = slice;

export const { resetState } = sampleActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.sample || initialState;

export const getSampleState = createSelector([selectDomain], (sampleState) => sampleState);

/* --------------------------------------------------------------------------------- */

export { sampleGetAPI, samplePostAPI, samplePutAPI, sampleDeleteAPI };
