// Change data from state using name

export const handleCheckBoxChange = (setState) => (e) => {
  const { checked, name } = e.target;

  setState((prev) => {
    return {
      ...prev,
      [name]: checked,
    };
  });
};
