/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Components
import { getLogs } from 'reducers/auditLogsReducer';
import { objectCleaner } from 'utils/objectCleaner';
import InventoryItems from './components/InventoryItems';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Assets
import styles from './index.module.scss';
import samplePicture from 'assets/images/bondpaper.png';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';

// Main component
const AuditTrail = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const [data, setData] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    sort_by: '',
  });

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getLogs(params));

      setData(response?.payload?.data?.data);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value, page: 1 }));

    const newObj = {
      page: 1,
      sort_by: value,
      q: filter?.search,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.search,
      sort_by: filter?.sort_by,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        sort_by: filter?.sort_by,
        per_page: filter?.per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handleLogs = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  useEffect(() => {
    handleLogs();
  }, []);

  return (
    <PrivateLayout pageTitle='Audit Trail'>
      <div className={styles?.auditTrail}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Audit Trail</h5>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_by'
                        onChange={handleSort}
                        className={styles?.select}
                        value={filter?.sort_by}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='ASC'>Ascending</option>
                        <option value='DESC'>Descending</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>

                <div className={styles?.body}>
                  <InventoryItems
                    data={data}
                    setFilter={setFilter}
                    handlePaginationClick={handlePaginationClick}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default AuditTrail;
