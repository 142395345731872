/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import { getRoles, updateFilter, selectRoles } from 'reducers/rolesReducer';

// Assets
import styles from './UserRolesFilter.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';

const UserRolesFilter = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const rolesState = useSelector(selectRoles);
  const { q, page, per_page, sort_by } = rolesState.filterState;

  const tryCatch = (params, defaultParams) => {
    try {
      dispatch(getRoles(params));
      dispatch(updateFilter(defaultParams));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    dispatch(updateFilter({ [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      dispatch(updateFilter({ page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        sort_by: sort_by,
        per_page: per_page,
      };

      const defaultParams = {
        page: 1,
        q: value,
        sort_by: sort_by,
        per_page: per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params, defaultParams);
    }, 1000);
  };

  const handleSort = (e) => {
    const { name, value } = e.target;

    dispatch(updateFilter({ [name]: value, page: 1 }));

    const newObj = {
      q: q,
      page: 1,
      sort_by: value,
      per_page: per_page,
    };

    const params = objectCleaner(newObj);

    tryCatch(params, newObj);
  };

  return (
    <>
      <Form.Group className={styles?.searchBar}>
        <GlassSVG />

        <FormControl name='q' placeholder='Search' value={q} onChange={handleSearch} />
      </Form.Group>

      <div className={styles?.selectContainer}>
        <Form.Select
          name='sort_by'
          onChange={handleSort}
          className={styles?.select}
          value={sort_by}
        >
          <option value='' hidden>
            Sort By
          </option>
          <option value='ASC'>Ascending</option>
          <option value='DESC'>Descending</option>
        </Form.Select>
      </div>
    </>
  );
};

export default UserRolesFilter;
