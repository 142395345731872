// Remove special characters then make it camel case

export const camelize = (str) => {
  return str
    .replace(/[^\w\s]/gi, '')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
