/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl } from 'react-bootstrap';

import FormNumber from 'components/GlobalUIs/FormNumber';
import ImageUpload from 'components/GlobalUIs/ImageUpload';
import { selectInventory, addItem } from 'reducers/inventoryReducer';

import styles from './AddItem.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const AddItem = ({ show = false, setShowAdd, handleInventory }) => {
  const dispatch = useDispatch();

  const inventoryState = useSelector(selectInventory);
  const { addLogs } = inventoryState;

  const [data, setData] = useState({
    warehouses: [],
    image: [],
    itemName: '',
    category: '',
    goodQuantity: '',
    warehouse_name: '',
    damageQuantity: '',
  });

  const handleSave = async () => {
    if (data?.userRoles === '') {
      toast.error('Please select role');
      return;
    }

    const objData = {
      item_name: data?.itemName,
      category: data?.category,
      warehouse_name: data?.warehouse_name,
      quantity: data?.goodQuantity,
      damaged_quantity: data?.damageQuantity,
    };

    const formData = new FormData();

    formData.append('image', data?.image[0]);
    formData.append('category', data?.category);
    formData.append('item_name', data?.itemName);
    formData.append('quantity', data?.goodQuantity);
    formData.append('warehouse_name', data?.warehouse_name);
    formData.append('damaged_quantity', data?.damageQuantity);

    try {
      const response = await dispatch(addItem(formData));

      if (response) {
        handleInventory(); // refresh data
        setShowAdd(false);
        toast.info('Successfully added item');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const getStorage = async () => {
      try {
        axios.get('api/warehouse').then((response) => {
          setData((prev) => ({ ...prev, warehouses: response?.data?.data }));
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getStorage();
  }, []);

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>Add New Item</p>

        <CloseSVG
          className={styles?.close}
          onClick={() => {
            setShowAdd(false);
          }}
        />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.group}>
          <Form.Label>Item Name</Form.Label>
          <FormControl
            name='itemName'
            value={data?.itemName}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Category</Form.Label>
          <FormControl
            name='category'
            value={data?.category}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Warehouse</Form.Label>
          <Form.Select name='warehouse_name' value={data?.warehouse_name} onChange={handleChange}>
            <option value='' hidden>
              Select Type
            </option>
            {data?.warehouses &&
              data?.warehouses.length > 0 &&
              data?.warehouses.map((dd) => (
                <option key={dd?.id} value={dd?.attributes?.warehouse_name}>
                  {dd?.attributes?.warehouse_name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <div className={styles?.quantities}>
          <Form.Group className={`${styles?.group} ${styles?.good}`}>
            <Form.Label>Good Quantity</Form.Label>
            <FormNumber
              name='goodQuantity'
              onChange={handleChange}
              placeholder='Enter here'
              value={data?.goodQuantity}
            />
          </Form.Group>

          <Form.Group className={`${styles?.group} ${styles?.damaged}`}>
            <Form.Label>Damage Quantity</Form.Label>
            <FormNumber
              name='damageQuantity'
              onChange={handleChange}
              placeholder='Enter here'
              value={data?.damageQuantity}
            />
          </Form.Group>
        </div>

        <Form.Group className={styles?.group}>
          <Form.Label>Item Image</Form.Label>

          <ImageUpload name='image' setState={setData} stateValue={data?.image} />
        </Form.Group>

        <Button
          onClick={handleSave}
          disabled={addLogs?.isLoading}
          className={styles?.processButton}
        >
          Add Item
        </Button>
      </div>
    </div>
  );
};

export default AddItem;
