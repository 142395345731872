// Import core & vendor packages below
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import { handleTextChange } from 'helpers/handleTextChange';

// Assets
import '../Styles/FormText.scss';
import { ReactComponent as EyeIcon } from 'assets/svg/eye.svg';
import { ReactComponent as HideIcon } from 'assets/svg/hide.svg';

// Main component
const FormText = ({
  type,
  name,
  Icon,
  setState,
  stateValue,
  placeholder,
  haveClass = '',
  noLabel = false,
  haveIcon = false,
  ...rest
}) => {
  const [formType, setFormType] = useState();

  const handleShowPassword = () => {
    setFormType('text');
  };

  const handleHidePassword = () => {
    setFormType('password');
  };

  const handlePassword = () => {
    formType === 'text' ? setFormType('password') : setFormType('text');
  };

  useEffect(() => {
    if (type) {
      setFormType(type);
    }
  }, [type]);

  return (
    <Form.Group className={`${haveClass} global-form-input`}>
      {!noLabel && <Form.Label>{name}</Form.Label>}

      {haveIcon && <Icon className='left-icon' />}

      <Form.Control
        {...rest}
        type={formType}
        value={stateValue}
        name={camelize(name)}
        placeholder={placeholder}
        onChange={handleTextChange(setState)}
        style={{ paddingLeft: haveIcon ? '50px' : '20px' }}
      />

      {formType === 'password' && (
        <HideIcon
          className='eye-icon hide'
          // eslint-disable-next-line multiline-comment-style
          // onMouseUp={handleHidePassword}
          // onMouseDown={handleShowPassword}
          onClick={handlePassword}
        />
      )}

      {formType === 'text' && name.includes('Password') && (
        <EyeIcon
          className='eye-icon'
          onMouseUp={handleHidePassword}
          onMouseDown={handleShowPassword}
        />
      )}
    </Form.Group>
  );
};

export default FormText;
