/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import { getRequest } from 'reducers/requestReducer';
import ReturnedItems from './components/ReturnedItems';
import BorrowedItems from './components/BorrowedItems';
import ConditionalRender from 'components/ConditionalRender';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Assets
import styles from './index.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';

// Main component
const Transaction = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const [selectTab, setSelectTab] = useState('Borrowed Item');

  const [borrowedData, setBorrowedData] = useState([]);

  const [returnData, setReturnData] = useState([]);

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    sort_by: '',
    per_page: '5',
    include: 'approvers',
    order_by: 'user_profile.status',
    transaction_type: 'BORROWED ITEM',
    // ['include[]']: 'approvers',
  });

  const handleFilter = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const tryCatch = async (params, transaction_type = filter?.transaction_type) => {
    if (transaction_type === 'BORROWED ITEM') {
      setBorrowedData([]);
    }

    if (transaction_type === 'RETURN ITEM') {
      setReturnData([]);
    }

    try {
      const response = await dispatch(getRequest(params));

      const newData = response?.payload?.data?.map((dd) => {
        const approverArr = dd?.relationships?.approvers?.data.map((app) => {
          return response?.payload?.included.find((id) => +id?.id === +app?.id);
        });

        return {
          ...dd,
          id: dd?.id,
          approver: approverArr,
          image: dd?.attributes?.image,
          status: dd?.attributes?.status,
          itemCode: dd?.attributes?.item_id,
          itemName: dd?.attributes?.item_name,
          stockNeeded: dd?.attributes?.quantity,
          borrowedBy: dd?.attributes?.borrowed_by,
          dateRequested: dd?.attributes?.created_at,
          typeOfTransaction: dd?.attributes?.transaction_type,
        };
      });

      if (transaction_type === 'BORROWED ITEM') {
        setBorrowedData(newData);
      }

      if (transaction_type === 'RETURN ITEM') {
        setReturnData(newData);
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      sort_by: value,
      q: filter?.search,
      page: filter?.page,
      per_page: filter?.per_page,
      ['include[]']: filter?.include,
      transaction_type: filter?.transaction_type,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleRequest = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      ['include[]']: filter?.include,
      transaction_type: filter?.transaction_type,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        q: value,
        page: 1,
        sort_by: filter?.sort_by,
        per_page: filter?.per_page,
        ['include[]']: filter?.include,
        transaction_type: filter?.transaction_type,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handlePaginationClick = ({ page, transaction_type = '' }) => {
    setFilter((p) => ({
      ...p,
      page: page,
    }));

    const newObj = {
      page: page,
      q: filter?.search,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      ['include[]']: filter?.include,
      transaction_type: transaction_type !== '' ? transaction_type : filter?.transaction_type,
    };

    const params = objectCleaner(newObj);

    const _type = transaction_type !== '' ? transaction_type : filter?.transaction_type;

    tryCatch(params, _type);
  };

  useEffect(() => {
    handleRequest();
  }, []);

  return (
    <PrivateLayout pageTitle='Transactions'>
      <div className={styles?.transaction}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          setSelectTab('Borrowed Item');
                          setFilter((p) => ({
                            ...p,
                            q: '',
                            transaction_type: 'BORROWED ITEM',
                          }));
                          handlePaginationClick({ page: 1, transaction_type: 'BORROWED ITEM' });
                        }}
                        className={`${selectTab === 'Borrowed Item' ? styles?.active : ''}`}
                      >
                        Borrowed Item
                      </Button>
                    </div>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          setSelectTab('Returned Item');
                          setFilter((p) => ({
                            ...p,
                            q: '',
                            transaction_type: 'RETURN ITEM',
                          }));
                          handlePaginationClick({ page: 1, transaction_type: 'RETURN ITEM' });
                        }}
                        className={`${selectTab === 'Returned Item' ? styles?.active : ''}`}
                      >
                        Returned Item
                      </Button>
                    </div>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='q'
                        value={filter?.q}
                        placeholder='Search'
                        onChange={handleSearch}
                      />
                    </Form.Group>

                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_by'
                        onChange={handleSort}
                        value={filter?.sort_by}
                        className={styles?.select}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='DESC'>Pending</option>
                        <option value='ASC'>Approved</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>

                <div className={styles?.body}>
                  <ConditionalRender
                    condition={selectTab === 'Borrowed Item'}
                    renderIf={
                      <BorrowedItems
                        data={borrowedData}
                        setFilter={setFilter}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />

                  <ConditionalRender
                    condition={selectTab === 'Returned Item'}
                    renderIf={
                      <ReturnedItems
                        data={returnData}
                        setFilter={setFilter}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Transaction;
