import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Modal, Button } from 'react-bootstrap';

import { selectApprover } from 'reducers/approverReducer';

import './ConfirmModal.scss';

const ConfirmModal = ({ show, title, handleClose, handleConfirm }) => {
  const approverState = useSelector(selectApprover);
  const { deleteLogs } = approverState;

  return (
    <Modal show={show} onHide={handleClose} centered className='confirmAction'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <Row className='w-100'>
            <Col>
              <div className='buttonContainer'>
                <Button
                  onClick={handleConfirm}
                  className='updateRoles'
                  disabled={deleteLogs?.isLoading}
                >
                  Confirm
                </Button>

                <Button onClick={handleClose} className='cancel'>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
