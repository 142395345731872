/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { selectApprover, addApprover } from 'reducers/approverReducer';

import styles from './AddApprover.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const AddApprover = ({ show = false, setShowAdd, refreshData }) => {
  const dispatch = useDispatch();

  const userData = useSelector(selectApprover);
  const { addLogs } = userData;

  const [data, setData] = useState({
    roles: [],
    type: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (data?.userRoles === '') {
      toast.error('Please select role');
      return;
    }

    const objData = {
      role_id: data?.type,
      is_required: 0,
    };

    try {
      const response = await dispatch(addApprover(objData));

      if (response) {
        refreshData();
        setShowAdd(false);
        toast.info('Successfully added approver');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        axios.get('api/roles').then((response) => {
          setData((prev) => ({ ...prev, roles: response?.data?.data }));
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getRoles();
  }, []);

  return (
    <div className={`${styles?.addApprover} ${show ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>Add Approver</p>

        <CloseSVG
          className={styles?.close}
          onClick={() => {
            setShowAdd(false);
          }}
        />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.group}>
          <Form.Label>User Roles</Form.Label>
          <Form.Select
            name='type'
            value={data?.type}
            onChange={handleChange}
            placeholder='Enter here'
          >
            <option value='' hidden>
              Select Role
            </option>
            {data &&
              data?.roles &&
              data?.roles.length >= 1 &&
              data.roles?.map((dd) => (
                <option key={dd?.id} value={dd?.id}>
                  {dd?.attributes?.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <Button
          onClick={handleSave}
          disabled={addLogs?.isLoading}
          className={styles?.addApproverBtn}
        >
          Add Approver
        </Button>
      </div>
    </div>
  );
};

export default AddApprover;
