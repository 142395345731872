/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import Approver from './Approver';
import { selectAuditLogs } from 'reducers/auditLogsReducer';
import TablePagination from 'components/GlobalUIs/Pagination';

import styles from './BorrowedItems.module.scss';

const BorrowedItems = ({ data, setFilter, handlePaginationClick }) => {
  const requestState = useSelector(selectAuditLogs);
  const { meta, logs } = requestState;

  const [showApprover, setShowApprover] = useState({
    data: null,
    show: false,
  });

  const closeApprover = () => {
    setShowApprover({
      data: null,
      show: false,
    });
  };

  console.log('data', data);

  return (
    <Table responsive className={styles?.reportsBorrowedTable}>
      <thead>
        <tr>
          <th>Image</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>Date Requested</th>
          <th>Borrowed By</th>
          <th>User Roles</th>
          <th>Stock Needed</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length >= 1 &&
          data.map((dd) => (
            <tr key={dd?.id}>
              <td>
                <Image src={dd?.image} />
              </td>
              <td>{dd?.id}</td>
              <td>{dd?.item_name}</td>
              <td>{dd?.created_at}</td>
              <td>{dd?.borrowed_by}</td>
              <td>{dd?.borrower_role}</td>
              <td>{dd?.stockNeeded}</td>
              <td>
                <span className={styles[`${dd?.status}`]}>{dd?.status}</span>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={10}>
            <TablePagination
              meta={meta}
              setParams={setFilter}
              loading={logs?.isLoading}
              handlePaginationClick={handlePaginationClick}
            />
          </td>
        </tr>
      </tfoot>

      <Approver show={showApprover?.show} data={showApprover?.data} handleClose={closeApprover} />
    </Table>
  );
};

export default BorrowedItems;
