// Import core & vendor packages below
import { Form } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import { handleCheckBoxChange } from 'helpers/handleCheckBoxChange';

// Main component
const FormCheckBox = ({ name, text, setState, stateValue, haveClass = '', ...rest }) => {
  return (
    <Form.Group className={`${haveClass} global-form-input-check`}>
      <Form.Check
        {...rest}
        label={text}
        type='checkbox'
        checked={stateValue}
        name={camelize(name)}
        onChange={handleCheckBoxChange(setState)}
      />
    </Form.Group>
  );
};

export default FormCheckBox;
