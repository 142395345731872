import React from 'react';
import { FormControl } from 'react-bootstrap';

const FormNumber = ({ name, onChange, placeholder, value, ...rest }) => {
  return (
    <FormControl
      {...rest}
      name={name}
      type='number'
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={(evt) =>
        (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
        evt.preventDefault()
      }
    />
  );
};

export default FormNumber;
