/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Modal, Button } from 'react-bootstrap';

import ImageUI from 'components/GlobalUIs/ImageUI';
import { approveRequest, selectRequest } from 'reducers/requestReducer';

import './index.scss';
import samplePicture from 'assets/images/placeholder.png';

const ViewRequest = ({ show, data, handleClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleDecline = async () => {
    setIsLoading(true);

    const params = {
      id: data?.id,
      is_approved: 0,
      status: 'rejected',
    };

    try {
      const response = await dispatch(approveRequest(params));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.message?.detail);
        return;
      }

      if (response) {
        handleClose();
        toast.info('Successfully Declined Request');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async () => {
    setIsLoading(true);

    const params = {
      id: data?.id,
      is_approved: 1,
      status: 'approved',
    };

    try {
      const response = await dispatch(approveRequest(params));

      console.log('RESPONSE', response);

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.message?.detail);
        return;
      }

      if (response) {
        handleClose();
        toast.info('Successfully Approved Request');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className='borrowedItem'>
      <Modal.Header closeButton>
        <Modal.Title>Borrowed Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && (
          <div className='body'>
            <div className='image'>
              <ImageUI src={data?.image} alt='Item' fallbackSrc={samplePicture} />
            </div>

            <div className='itemDetails'>
              <Row className='mb-3'>
                <Col>
                  <p>
                    Item Code <span>{data?.itemCode}</span>
                  </p>
                </Col>
                <Col>
                  <p>
                    Type of Transaction <span>{data?.typeOfTransaction}</span>
                  </p>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <p>
                    Item Name <span>{data?.itemName}</span>
                  </p>
                </Col>
                <Col>
                  <p>
                    Stock Needed <span>{data?.stockNeeded}</span>
                  </p>
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <p>
                    Date Requested <span>{data?.dateRequested}</span>
                  </p>
                </Col>
                <Col>
                  <p>
                    Status <span>{data?.status}</span>
                  </p>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col sm={6}>
                  <p>
                    Borrowed by <span>{data?.borrowedBy}</span>
                  </p>
                </Col>
              </Row>

              <div className='buttonContainer mb-4'>
                <Button disabled={isLoading} onClick={handleApprove} className='approved'>
                  Approve
                </Button>
                <Button disabled={isLoading} onClick={handleDecline} className='decline'>
                  Decline
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewRequest;
