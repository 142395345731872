/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import { selectAuth } from 'reducers/authReducer';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { updateProfile, getUsersState } from 'reducers/usersReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow left.svg';

// Main component
const UserPassword = () => {
  const dispatch = useDispatch();

  const userState = useSelector(getUsersState);
  const { updateLogs } = userState;

  const authState = useSelector(selectAuth);
  const { userInfo } = authState;

  const [data, setData] = useState({
    newPassword: '',
    currentPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    const result = checkEmpty(data);

    if (result) {
      toast.error('Please fill in the blank to proceed');
      return;
    }

    const formData = new FormData();

    formData.append('password', data?.newPassword);
    formData.append('current_password', data?.currentPassword);
    formData.append('password_confirmation', data?.confirmPassword);

    try {
      const obj = {
        id: userInfo?.id,
        params: formData,
      };

      const response = await dispatch(updateProfile(obj));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        setData({
          newPassword: '',
          currentPassword: '',
          confirmPassword: '',
        });

        toast.success('Successfully updated Password');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <PrivateLayout pageTitle='Update Password'>
      <div className={styles?.password}>
        <Container>
          <Row>
            <Col>
              <Link className={styles?.back} to={'/'}>
                <ArrowSVG />
                Back to Dashboard
              </Link>

              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <h5>Change Password</h5>
                </div>

                <div className={styles?.body}>
                  <div className={styles?.forms}>
                    <Form.Group className={styles?.group}>
                      <Form.Label>Current Password</Form.Label>

                      <FormControl
                        type='password'
                        name='currentPassword'
                        onChange={handleChange}
                        placeholder='Enter here'
                        value={data?.currentPassword}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>New Password</Form.Label>
                      <FormControl
                        type='password'
                        name='newPassword'
                        onChange={handleChange}
                        placeholder='Enter here'
                        value={data?.newPassword}
                      />
                    </Form.Group>

                    <Form.Group className={styles?.group}>
                      <Form.Label>Confirm Password</Form.Label>
                      <FormControl
                        type='password'
                        name='confirmPassword'
                        onChange={handleChange}
                        placeholder='Enter here'
                        value={data?.confirmPassword}
                      />
                    </Form.Group>
                  </div>

                  <Button disabled={updateLogs?.isLoading} onClick={handleUpdate}>
                    Update
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default UserPassword;
