/* eslint-disable */
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  addUser,
  getUsers,
  editUser,
  getProfile,
  deleteUser,
  updateUser,
  declineUser,
  approveUser,
  updateProfile,
} from './thunks/usersThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  included: [],
  logs: initialLog,
  approveLogs: initialLog,
  declineLogs: initialLog,
  updateLogs: initialLog,
  addLogs: initialLog,
  deleteLogs: initialLog,

  // Profile Data
  profileData: {},
  profileLogs: initialLog,

  // For Update Profile
  updateLogs: initialLog,

  // For Edit Logs
  editLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    resetState: (state, action) => {
      state.users = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [getUsers.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getUsers.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [approveUser.fulfilled]: (state, action) => {
      state.approveLogs.isSuccess = true;
      state.approveLogs.isLoading = false;
    },
    [approveUser.pending]: (state) => {
      state.approveLogs.error = '';
      state.approveLogs.isFailed = false;
      state.approveLogs.isLoading = true;
      state.approveLogs.isSuccess = false;
    },
    [approveUser.rejected]: (state, action) => {
      state.approveLogs.isFailed = true;
      state.approveLogs.isLoading = false;
      state.approveLogs.error = action?.payload?.errors[0]?.detail;
    },
    [declineUser.fulfilled]: (state, action) => {
      state.declineLogs.isSuccess = true;
      state.declineLogs.isLoading = false;
    },
    [declineUser.pending]: (state) => {
      state.declineLogs.error = '';
      state.declineLogs.isFailed = false;
      state.declineLogs.isLoading = true;
      state.declineLogs.isSuccess = false;
    },
    [declineUser.rejected]: (state, action) => {
      state.declineLogs.isFailed = true;
      state.declineLogs.isLoading = false;
      state.declineLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateUser.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateUser.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [addUser.fulfilled]: (state, action) => {
      state.addLogs.isSuccess = true;
      state.addLogs.isLoading = false;
    },
    [addUser.pending]: (state) => {
      state.addLogs.error = '';
      state.addLogs.isFailed = false;
      state.addLogs.isLoading = true;
      state.addLogs.isSuccess = false;
    },
    [addUser.rejected]: (state, action) => {
      state.addLogs.isFailed = true;
      state.addLogs.isLoading = false;
      state.addLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteUser.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deleteUser.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.profileLogs.isSuccess = true;
      state.profileLogs.isLoading = false;
      state.profileData = action.payload.data;
    },
    [getProfile.pending]: (state) => {
      state.profileLogs.error = '';
      state.profileLogs.isFailed = false;
      state.profileLogs.isLoading = true;
      state.profileLogs.isSuccess = false;
    },
    [getProfile.rejected]: (state, action) => {
      state.profileLogs.isFailed = true;
      state.profileLogs.isLoading = false;
      state.profileLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateProfile.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [editUser.fulfilled]: (state, action) => {
      state.editLogs.isSuccess = true;
      state.editLogs.isLoading = false;
    },
    [editUser.pending]: (state) => {
      state.editLogs.error = '';
      state.editLogs.isFailed = false;
      state.editLogs.isLoading = true;
      state.editLogs.isSuccess = false;
    },
    [editUser.rejected]: (state, action) => {
      state.editLogs.isFailed = true;
      state.editLogs.isLoading = false;
      state.editLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: usersActions, reducer: usersReducer } = slice;

export const { resetState } = usersActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.users || initialState;

export const getUsersState = createSelector([selectDomain], (usersState) => usersState);

/* --------------------------------------------------------------------------------- */

export {
  getUsers,
  addUser,
  getProfile,
  deleteUser,
  approveUser,
  updateProfile,
  updateUser,
  declineUser,
  editUser,
};
