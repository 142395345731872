/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Form, Image, Container, Row, Col } from 'react-bootstrap';

import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

import styles from './HistorySidebar.module.scss';
import { ReactComponent as CalendarSVG } from 'assets/svg/calendar.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow down small.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/arrow right.svg';

const HistorySidebar = () => {
  const [history, setHistory] = useState('Last 7 Days');

  const [filter, setFilter] = useState('7');
  const [data, setData] = useState();

  const getDate = (d) => {
    const dateObj = new Date(d);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return dateObj.toLocaleDateString(undefined, options);
  };

  const getTime = (d) => {
    const dateObj = new Date(d);

    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return dateObj.toLocaleTimeString(undefined, options);
  };

  const getHistory = (value = '') => {
    try {
      axios
        .get('api/history', {
          params: {
            date: value ? value : filter,
          },
        })
        .then((response) => {
          const resData = response?.data?.data;
          const cutData = resData.slice(0, 5);
          const newData = cutData.map((dd) => {
            return {
              ...dd,
              image: dd?.image,
              date: dd?.created_at,
              time: dd?.created_at,
              title: dd?.item_name,
              quantity: dd?.quantity,
              type: dd?.transaction_type,
            };
          });

          setData(newData);
        });
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    getHistory();
  }, []);

  const canViewTransaction = checkStringPermission('can view transactions module');

  return (
    <div className={styles?.history}>
      <div className={styles?.header}>
        <h5>History</h5>

        <div className={styles?.selectContainer}>
          <Form.Select
            value={filter}
            className={styles?.select}
            onChange={(e) => {
              setFilter(e.target.value);
              getHistory(e.target.value);
            }}
          >
            <option value='7'>Last 7 Days</option>
            <option value='30'>Last 30 Days</option>
            <option value='year'>Last 1 Year</option>
          </Form.Select>
        </div>
      </div>

      <div className={styles?.body}>
        {data &&
          data.length >= 1 &&
          data.map((dd, idx) => {
            return (
              <div key={idx} className={styles?.list}>
                <Image src={dd?.image} />

                <div className={styles?.details}>
                  <h6>{`${dd?.quantity} ${dd?.title}`}</h6>

                  <p className={styles?.type}>
                    <ArrowSVG className={styles?.arrow} />
                    {dd?.type}
                  </p>

                  <p className={styles?.datetime}>
                    <span>
                      <CalendarSVG className={styles?.calendar} /> {getDate(dd?.date)}
                    </span>{' '}
                    {getTime(dd?.time)}
                  </p>
                </div>
              </div>
            );
          })}

        <ConditionalRender
          condition={canViewTransaction}
          renderIf={
            <div className={`${styles?.list} ${styles?.viewmore}`}>
              <Link to={'/transactions'}>
                View More <ArrowRightSVG />
              </Link>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default HistorySidebar;
