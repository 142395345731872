// Change data from state using name

export const handleTextChange = (setState) => (e) => {
  const { value, name } = e.target;

  setState((prev) => {
    return {
      ...prev,
      [name]: value,
    };
  });
};
