/* eslint-disable prefer-const */
import { useSelector } from 'react-redux';
import { selectAuth } from 'reducers/authReducer';

export const checkStringPermission = (string) => {
  const userData = useSelector(selectAuth);

  const { permissions } = userData;

  if (!permissions.length > 0) {
    return;
  }

  return permissions.some((dd) => dd === string);
};
