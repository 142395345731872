/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import { addRole, selectRoles } from 'reducers/rolesReducer';

import styles from './AddRoles.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const AddRoles = ({ show = false, setShowAdd, setRefreshRole }) => {
  const dispatch = useDispatch();

  const rolesState = useSelector(selectRoles);
  const { addLogs } = rolesState;

  const [data, setData] = useState({
    userRoles: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAdd = async () => {
    if (data?.userRoles === '') {
      toast.error('Please enter role name');
      return;
    }

    const params = { name: data?.userRoles };

    try {
      const response = await dispatch(addRole(params));

      if (response) {
        setShowAdd(false);
        setRefreshRole(true);
        setData({ userRoles: '' });
        toast.info('Successfully Added role');
      }

      setData((prev) => ({ ...prev, userRoles: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={`${styles?.addRoles} ${show ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>Create New Roles</p>

        <CloseSVG
          className={styles?.close}
          onClick={() => {
            setShowAdd(false);
          }}
        />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.group}>
          <Form.Label>Role</Form.Label>
          <Form.Control
            name='userRoles'
            value={data?.userRoles}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Button disabled={addLogs?.isLoading} onClick={handleAdd} className={styles?.addApprover}>
          Add New Roles
        </Button>
      </div>
    </div>
  );
};

export default AddRoles;
