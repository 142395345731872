/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getRequest = createAsyncThunk('getRequest', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: params,
    url: `/api/transaction`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const approveRequest = createAsyncThunk(
  'approveRequest',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `/api/approve/transaction`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
