// Change data from state using name

export const handleUploadChange = (setState) => (e) => {
  const { files, name } = e.target;

  setState((prev) => {
    return {
      ...prev,
      [name]: files,
    };
  });
};
