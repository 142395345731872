/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getRoles = createAsyncThunk('getRoles', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `/api/roles`,
    params: params,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const updateRoles = createAsyncThunk(
  'updateRoles',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `/api/roles/${id}`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const addRole = createAsyncThunk('addRole', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: `/api/roles`,
    data: params,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const addPermission = createAsyncThunk(
  'addPermission',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/role/${id}`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const removePermission = createAsyncThunk(
  'removePermission',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/role/${id}/remove-permission`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteRole = createAsyncThunk('deleteRole', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `api/roles/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
