/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Image,
  Table,
  Button,
  Dropdown,
  Container,
  FormControl,
} from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUIs/ImageUI';
import { objectCleaner } from 'utils/objectCleaner';
import AddItem from './components/Modals/AddItem/AddItem';
import TablePagination from 'components/GlobalUIs/Pagination';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { checkStringPermission } from 'helpers/filteredPermissions';
import RequestItem from './components/Modals/RequestItem/RequestItem';
import { getInventory, selectInventory } from 'reducers/inventoryReducer';

// Assets
import styles from './index.module.scss';
import samplePicture from 'assets/images/placeholder.png';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';
import ConditionalRender from 'components/ConditionalRender';

// Main component
const Inventory = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const navigate = useNavigate();

  const inventoryState = useSelector(selectInventory);
  const { meta, logs } = inventoryState;

  const [data, setData] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    sort_by: '',
    // order_by: 'user_profile.status',
    order_by: 'status',
  });

  const [showAdd, setShowAdd] = useState(false);
  const [showRequest, setShowRequest] = useState({
    show: false,
    type: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleViewInventory = (data) => {
    navigate(`/inventory/${data?.id}`);
    setShowRequest({
      show: false,
      type: '',
    });
  };

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      sort_by: value,
      q: filter?.search,
      page: filter?.page,
      per_page: filter?.per_page,
      order_by: filter?.order_by,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        sort_by: filter?.sort_by,
        order_by: filter?.order_by,
        per_page: filter?.per_page,
        filter_status: filter?.filter_status,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getInventory(params));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          image: dd?.attributes?.image,
          itemCode: dd?.id,
          itemName: dd?.attributes?.item_name,
          dateAdded: dd?.attributes?.created_at,
          addedBy: dd?.attributes?.added_by,
          storage: dd?.attributes?.storage,
          type: dd?.attributes?.type,
          totalStock: dd?.attributes?.total_stocks,
          goodQuantity: dd?.attributes?.good_quantity,
          damageQuantity: dd?.attributes?.damaged_quantity,
          status: dd?.attributes?.status,
        };
      });

      setData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleInventory = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      order_by: filter?.order_by,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.q,
      sort_by: filter?.sort_by,
      order_by: filter?.order_by,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  useEffect(() => {
    handleInventory();
  }, []);

  const canCreateItem = checkStringPermission('can create new item');
  const canRequestItem = checkStringPermission('can request item');

  return (
    <PrivateLayout pageTitle='Inventory'>
      <div className={`${styles?.inventory} ${showAdd ? styles?.show : ''}`}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Inventory Item List</h5>

                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleSearch}
                      />
                    </Form.Group>
                  </div>

                  <div className={styles?.filter}>
                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_by'
                        onChange={handleSort}
                        className={styles?.select}
                        value={filter?.sort_by}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='ASC'>Available</option>
                        <option value='DESC'>Unavailable</option>
                      </Form.Select>
                    </div>

                    <ConditionalRender
                      condition={canCreateItem}
                      renderIf={
                        <Button
                          className={styles?.processItem}
                          onClick={() => {
                            setShowAdd(true);
                          }}
                        >
                          <CirclePlus />
                          Add New Item
                        </Button>
                      }
                    />

                    <ConditionalRender
                      condition={canRequestItem}
                      renderIf={
                        <Dropdown>
                          <Dropdown.Toggle variant='success' className={styles?.requestSettings}>
                            Request Item
                          </Dropdown.Toggle>

                          <Dropdown.Menu className='selections'>
                            <Dropdown.Item
                              onClick={() => {
                                setShowRequest({
                                  type: 'me',
                                  show: true,
                                });
                              }}
                              className={styles?.requestButton}
                            >
                              For me
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setShowRequest({
                                  type: 'others',
                                  show: true,
                                });
                              }}
                              className={styles?.requestButton}
                            >
                              For another user
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      }
                    />
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.processTable}>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Date Added</th>
                        <th>Added By</th>
                        <th>Storage</th>
                        <th>Type</th>
                        <th>Total Stock</th>
                        <th>Good Quantity</th>
                        <th>Damage Quantity</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length >= 1 &&
                        data.map((dd) => (
                          <tr key={dd?.id} onClick={() => handleViewInventory(dd)}>
                            <td>
                              <ImageUI
                                src={dd?.image}
                                alt={dd?.itemName}
                                fallbackSrc={samplePicture}
                              />
                            </td>
                            <td>{dd?.itemCode}</td>
                            <td>{dd?.itemName}</td>
                            <td>{dd?.dateAdded}</td>
                            <td>{dd?.addedBy}</td>
                            <td>{dd?.storage}</td>
                            <td>{dd?.type}</td>
                            <td>{dd?.totalStock}</td>
                            <td>
                              <span className={styles?.goodQuantity}>{dd?.goodQuantity}</span>
                            </td>
                            <td>
                              <span className={styles?.damageQuantity}>{dd?.damageQuantity}</span>
                            </td>
                            <td>
                              <span
                                className={
                                  styles[
                                    `${dd?.status === 'Available' ? 'Available' : 'NotAvailable'}`
                                  ]
                                }
                              >
                                {dd?.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <TablePagination
                            meta={meta}
                            setParams={setFilter}
                            loading={logs?.isLoading}
                            handlePaginationClick={handlePaginationClick}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>

              <AddItem show={showAdd} handleInventory={handleInventory} setShowAdd={setShowAdd} />

              <RequestItem
                show={showRequest?.show}
                type={showRequest?.type}
                setShowRequest={setShowRequest}
                handleInventory={handleInventory}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Inventory;
