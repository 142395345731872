/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import ImageUpload from 'components/GlobalUIs/ImageUpload';

import styles from './AddProcess.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const AddProcess = ({ show = false, setShowAdd }) => {
  const [data, setData] = useState({
    name: '',
    stock: '',
    image: '',
    status: '',
    addedBy: '',
    dateAdded: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className={`${styles?.addProcess} ${show ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>Process Item</p>

        <CloseSVG
          className={styles?.close}
          onClick={() => {
            setShowAdd(false);
          }}
        />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.group}>
          <Form.Label>Item Name</Form.Label>
          <FormControl
            name='name'
            value={data?.name}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Item Image</Form.Label>

          <ImageUpload name='image' setState={setData} stateValue={data?.image} />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Date Added</Form.Label>
          <FormControl
            type='date'
            name='dateAdded'
            value={data?.dateAdded}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Added By</Form.Label>
          <FormControl
            name='addedBy'
            value={data?.addedBy}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Stock</Form.Label>
          <FormControl
            name='stock'
            value={data?.stock}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Status</Form.Label>

          <div className={styles?.buttons}>
            <Button
              onClick={() => {
                setData((prev) => {
                  return {
                    ...prev,
                    status: 'IN',
                  };
                });
              }}
              className={`${data?.status === 'IN' ? styles?.active : ''}`}
            >
              IN
            </Button>
            <Button
              onClick={() => {
                setData((prev) => {
                  return {
                    ...prev,
                    status: 'OUT',
                  };
                });
              }}
              className={`${data?.status === 'OUT' ? styles?.active : ''}`}
            >
              OUT
            </Button>
          </div>
        </Form.Group>

        <Button className={styles?.processButton}>Process Item</Button>
      </div>
    </div>
  );
};

export default AddProcess;
