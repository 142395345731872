/* eslint-disable no-unused-vars */
import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectAuditLogs } from 'reducers/auditLogsReducer';
import TablePagination from 'components/GlobalUIs/Pagination';

import styles from './InventoryItems.module.scss';

const InventoryItems = ({ data, setFilter, handlePaginationClick }) => {
  const auditState = useSelector(selectAuditLogs);
  const { meta, logs } = auditState;

  const getDate = (d) => {
    const dateObj = new Date(d);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return dateObj.toLocaleDateString(undefined, options);
  };

  const getTime = (d) => {
    const dateObj = new Date(d);

    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return dateObj.toLocaleTimeString(undefined, options);
  };

  return (
    <Table responsive className={styles?.inventoryTable}>
      <thead>
        <tr>
          <th>User ID</th>
          <th>Performed By</th>
          <th>Transactions Type</th>
          <th>User Roles</th>
          <th>Date</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length >= 1 &&
          data.map((dd) => (
            <tr key={dd?.id}>
              <td>{dd?.attributes?.causer_id}</td>
              <td>{dd?.attributes?.performed_by}</td>
              <td>{dd?.attributes?.properties?.attributes?.transaction_type}</td>
              <td>{dd?.attributes?.user_role}</td>
              <td>{getDate(dd?.attributes?.created_at)}</td>
              <td>{getTime(dd?.attributes?.created_at)}</td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={10}>
            <TablePagination
              meta={meta}
              setParams={setFilter}
              loading={logs?.isLoading}
              handlePaginationClick={handlePaginationClick}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default InventoryItems;
