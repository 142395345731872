/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';

import { deleteRole, selectRoles, resetDelete } from 'reducers/rolesReducer';

import './DeleteModal.scss';

const DeleteModal = ({ handleRoles, show, handleClose, data }) => {
  const dispatch = useDispatch();

  const rolesState = useSelector(selectRoles);
  const { deleteLogs } = rolesState;

  const [disabled, setDisabled] = useState(false);

  const handleDelete = async () => {
    setDisabled(true);

    await dispatch(deleteRole(data?.id));

    setTimeout(() => {
      setDisabled(false);
    }, [1500]);
  };

  useEffect(() => {
    if (deleteLogs?.isFailed) {
      toast.error(deleteLogs?.error);
    }

    if (deleteLogs?.isSuccess) {
      handleClose();
      handleRoles();
      toast.info('Successfully delete role');
    }

    dispatch(resetDelete());
  }, [deleteLogs]);

  useEffect(() => {
    console.log('deleteLogs?.isLoading', deleteLogs?.isLoading);
  }, [deleteLogs?.isLoading]);

  return (
    <Modal show={show} onHide={handleClose} centered className='deleteRole'>
      <Modal.Header closeButton>
        <Modal.Title>Delete Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <Row className='w-100'>
            <Col>
              <Form.Group className='buttons'>
                <Button disabled={disabled} onClick={handleDelete} className='yes'>
                  Yes
                </Button>

                <Button onClick={handleClose} className='decline'>
                  Decline
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
