// Import core & vendor packages below
import { Button } from 'react-bootstrap';

// Components

// Main component
const ButtonSubmit = ({ value, type, isDisabled, ...rest }) => {
  return (
    <Button type={type} disabled={isDisabled} {...rest}>
      {value}
    </Button>
  );
};

export default ButtonSubmit;
