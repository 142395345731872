/* eslint-disable multiline-comment-style */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';

import styles from '../Styles/Chart.module.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ options, data }) => {
  return <Bar options={options} data={data} />;
};

export default BarChart;
