/* eslint-disable newline-per-chained-call */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Components
import Approver from './components/Approver';
import AddProcess from './components/AddProcess';
import { objectCleaner } from 'utils/objectCleaner';
import TablePagination from 'components/GlobalUIs/Pagination';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { getRequest, selectRequest } from 'reducers/requestReducer';
import SamplePagination from 'components/GlobalUIs/SamplePagination';
import { getItemDetails, selectInventory } from 'reducers/inventoryReducer';

// Assets
import styles from './index.module.scss';
import samplePicture from 'assets/images/bondpaper.png';
import { ReactComponent as ListSVG } from 'assets/svg/list.svg';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as PrintSVG } from 'assets/svg/print.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow left.svg';
import { ReactComponent as DownloadSVG } from 'assets/svg/download.svg';

// Main component
const ViewItem = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const requestState = useSelector(selectRequest);
  const { meta, logs } = requestState;

  const timerRef = useRef(null);

  const [data, setData] = useState({
    details: {},
    tableData: [],
  });

  const [showApprover, setShowApprover] = useState({
    data: null,
    show: false,
  });

  const [filter, setFilter] = useState({
    id: 1,
    q: '',
    to: '',
    page: 1,
    from: '',
    per_page: '5',
    include: 'approvers',
  });

  const [showAdd, setShowAdd] = useState(false);

  const handleAPI = async () => {
    try {
      const payload = {
        id: id,
        params: {
          ['include[]']: 'transactions.approvers',
        },
      };

      const response = await dispatch(getItemDetails(payload));

      const newData = {
        ...response?.payload?.data?.data?.attributes,
        id: response?.payload?.data?.data?.id,
      };

      setData((prev) => ({ ...prev, details: newData }));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const getItemHistory = (id = null) => {
    if (id === null) return;

    const params = {
      inventory_id: id,
      page: filter?.page,
      per_page: filter?.per_page,
      ['include[]']: filter?.include,
    };

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        inventory_id: id,
        per_page: filter?.per_page,
        ['include[]']: filter?.include,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const tryCatch = async (params) => {
    try {
      const response = await dispatch(getRequest(params));

      let tbleData = [];
      let newData = [];

      console.log('response', response);

      if (response?.payload?.data) {
        tbleData = response?.payload?.data?.filter((dd) => dd?.type === 'inventory_transactions');

        newData = tbleData?.map((dd) => {
          const approverArr = dd?.relationships?.approvers?.data.map((app) => {
            return response?.payload?.included.find((id) => +id?.id === +app?.id);
          });

          return {
            ...dd,
            id: dd?.id,
            itemCode: dd?.id,
            approver: approverArr,
            image: dd?.attributes?.image,
            status: dd?.attributes?.status,
            itemName: dd?.attributes?.item_name,
            stockNeeded: dd?.attributes?.quantity,
            borrowedBy: dd?.attributes?.borrowed_by,
            dateRequested: dd?.attributes?.created_at,
            typeOfTransaction: dd?.attributes?.transaction_type,
          };
        });
      }

      setData((prev) => ({ ...prev, tableData: newData }));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleExport = () => {
    if (filter?.from === '' && filter?.to !== '') {
      toast.warning('Please select dates');
      return;
    }

    if (filter?.from !== '' && filter?.to === '') {
      toast.warning('Please select dates');
      return;
    }

    const newObj = {
      to: filter?.to,
      from: filter?.from,
      transaction_type: filter?.transaction_type === 'BORROWED ITEM' ? 'BORROWED' : 'RETURNED',
    };

    const params = objectCleaner(newObj);

    axios({
      method: 'get',
      params: params,
      responseType: 'blob',
      url: `/api/transaction/export/${id}`,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          inventory_id: id,
          to: _dateRange?.to,
          from: _dateRange?.from,
          sort_by: filter?.sort_by,
          per_page: filter?.per_page,
          ['include[]']: filter?.include,
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      q: filter?.q,
      inventory_id: id,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      ['include[]']: filter?.include,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const closeApprover = () => {
    setShowApprover({
      data: null,
      show: false,
    });
  };

  useEffect(() => {
    handleAPI();
  }, []);

  useEffect(() => {
    if (id) {
      setFilter((prev) => ({
        ...prev,
        id: id,
      }));

      getItemHistory(id);
    }
  }, [id]);

  console.log('data', data);

  return (
    <PrivateLayout pageTitle='View Item'>
      <div className={styles?.inventory}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <Link className={styles?.back} to={'/inventory'}>
                <ArrowSVG />
                Back to Inventory
              </Link>

              <div className={styles?.itemDetails}>
                <Row>
                  <Col sm={2}>
                    <div className={`${styles?.details} ${styles?.center}`}>
                      <Image className={styles?.image} src={data?.details?.image} />
                    </div>
                  </Col>
                  <Col>
                    <div className={styles?.details}>
                      <Form.Group className={styles?.group}>
                        <Form.Label>Item Code:</Form.Label>
                        <FormControl name='code' value={data?.details?.id} />
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label>Item Name:</Form.Label>
                        <FormControl name='name' value={data?.details?.item_name} />
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label>Date Added:</Form.Label>
                        <FormControl name='dateAdded' value={data?.details?.created_at} />
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label>Storage:</Form.Label>
                        <FormControl name='storage' value={data?.details?.storage} />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col>
                    <div className={styles?.details}>
                      <Form.Group className={styles?.group}>
                        <Form.Label>Type:</Form.Label>
                        <FormControl name='type' value={data?.details?.type} />
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label>Total Stocks:</Form.Label>
                        <FormControl name='totalStock' value={data?.details?.total_stocks} />
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label className={styles?.good}>Good Quantity:</Form.Label>
                        <FormControl name='goodQuantity' value={data?.details?.good_quantity} />
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label className={styles?.damaged}>Damage Quantity:</Form.Label>
                        <FormControl
                          name='damageQuantity'
                          value={data?.details?.damaged_quantity}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col>
                    <div className={styles?.details}>
                      <Form.Group className={styles?.group}>
                        <Form.Label>Added by:</Form.Label>

                        <p>{data?.details?.added_by}</p>
                      </Form.Group>

                      <Form.Group className={styles?.group}>
                        <Form.Label>Status:</Form.Label>
                        <span
                          className={
                            styles[
                              `${
                                data?.details?.status === 'Available' ? 'Available' : 'NotAvailable'
                              }`
                            ]
                          }
                        >
                          {data?.details?.status}
                        </span>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Item History</h5>

                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='q'
                        value={filter?.q}
                        placeholder='Search'
                        onChange={handleSearch}
                      />
                    </Form.Group>
                  </div>

                  <div className={styles?.filter}>
                    <div className={styles?.from}>
                      <p className={styles?.title}>
                        <ListSVG />
                        From
                      </p>

                      <FormControl
                        name='from'
                        type='date'
                        value={filter?.from}
                        onChange={handleDates}
                        className={styles?.fromInput}
                        max={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div className={styles?.to}>
                      <p className={styles?.title}>To</p>

                      <FormControl
                        name='to'
                        type='date'
                        value={filter?.to}
                        min={filter?.from}
                        className={styles?.toInput}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => {
                          if (filter?.from !== '') {
                            handleDates(e);
                            return;
                          }

                          toast.warning('Please select from first');
                        }}
                      />
                    </div>

                    <div className={styles?.tableActions}>
                      <Button onClick={handleExport} className={styles?.download}>
                        <DownloadSVG />
                        Export
                      </Button>
                    </div>
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.historyTable}>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Item No.</th>
                        <th>Item Name</th>
                        <th>Date of Transaction</th>
                        <th>Transact By</th>
                        <th>Type of Transaction</th>
                        <th>Approved by</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.tableData &&
                        data?.tableData?.length >= 1 &&
                        data?.tableData.map((dd) => (
                          <tr key={dd?.id}>
                            <td>
                              <Image src={dd?.attributes?.image} />
                            </td>
                            <td>{dd?.attributes?.item_id}</td>
                            <td>{dd?.attributes?.item_name}</td>
                            <td>{dd?.attributes?.created_at}</td>
                            <td>{dd?.attributes?.requester}</td>
                            <td>{dd?.attributes?.transaction_type}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  setShowApprover({
                                    show: true,
                                    data: dd?.approver,
                                  });
                                }}
                                className={styles?.button}
                              >
                                Approver
                              </Button>
                            </td>
                            <td>
                              <span className={styles[dd?.attributes?.status]}>
                                {dd?.attributes?.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <TablePagination
                            meta={meta}
                            setParams={setFilter}
                            loading={logs?.isLoading}
                            handlePaginationClick={handlePaginationClick}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>

              <AddProcess show={showAdd} setShowAdd={setShowAdd} />
            </Col>
          </Row>
        </Container>
      </div>

      <Approver show={showApprover?.show} data={showApprover?.data} handleClose={closeApprover} />
    </PrivateLayout>
  );
};

export default ViewItem;
