/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getLogs = createAsyncThunk('getLogs', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: params,
    url: `/api/activity`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
