/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getLogs } from './thunks/auditLogsThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  logs: initialLog,
};

const slice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {},
  extraReducers: {
    [getLogs.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.data?.meta;
    },
    [getLogs.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getLogs.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: auditLogsActions, reducer: auditLogsReducer } = slice;

export const { updateUserinfo } = auditLogsActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.auditLogs || initialState;

export const selectAuditLogs = createSelector([selectDomain], (auditLogsState) => auditLogsState);

/* --------------------------------------------------------------------------------- */

export { getLogs };
