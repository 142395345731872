/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Table, Button, Container, FormControl } from 'react-bootstrap';

// Components
import DoneTab from './components/Done/Done';
import Approver from './components/Modals/Approver';
import { objectCleaner } from 'utils/objectCleaner';
import ApprovalTab from './components/Approval/Approval';
import ViewRequest from './components/Modals/ViewRequest';
import ConditionalRender from 'components/ConditionalRender';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import ConfirmModal from './components/Modals/Confirm/ConfirmModal';
import { getRequest, selectRequest } from 'reducers/requestReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as CheckApproveSVG } from 'assets/svg/checkApprove.svg';

// Main component
const ItemRequest = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const navigate = useNavigate();

  const requestState = useSelector(selectRequest);
  const { meta, logs } = requestState;

  const [doneData, setDoneData] = useState([]);

  const [approvalData, setApprovalData] = useState([]);

  const [selectTab, setSelectTab] = useState('for-approval-user');

  const [showConfirm, setShowConfirm] = useState({
    data: '',
    title: '',
    show: false,
    active: null,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    sort_by: '',
    order_by: 'status',
    include: 'approvers',
    filter_approver: 'for-approval-user',
  });

  const [showApprover, setShowApprover] = useState({
    data: null,
    show: false,
  });

  const [showRequest, setShowRequest] = useState({
    data: null,
    show: false,
  });

  const handleCloseConfirm = () => {
    setShowConfirm({
      data: '',
      title: '',
      show: false,
      active: null,
    });
  };

  const closeApprover = () => {
    setShowApprover({
      data: null,
      show: false,
    });
  };

  const closeRequest = () => {
    setShowRequest({
      data: null,
      show: false,
    });
  };

  const showActivateModal = (dd) => {
    setShowConfirm({
      data: dd,
      show: true,
      title: 'Confirm action approve all?',
    });
  };

  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    const newObj = {
      sort_by: value,
      q: filter?.search,
      page: filter?.page,
      per_page: filter?.per_page,
      order_by: filter?.order_by,
      ['include[]']: 'approvers',
      filter_approver: filter?.filter_approver,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        sort_by: filter?.sort_by,
        order_by: filter?.order_by,
        per_page: filter?.per_page,
        ['include[]']: 'approvers',
        filter_approver: filter?.filter_approver,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handleApproveAll = async () => {
    setConfirmLoading(true);

    try {
      axios.get('api/approve/all/transaction').then((response) => {
        refreshData();
        handleCloseConfirm();
        toast.success('Successfully approved all pending query');
      });
    } catch (error) {
      console.log('Error', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const refreshData = () => {
    setFilter({
      page: 1,
      search: '',
      per_page: 5,
      sort_by: '',
      order_by: 'status',
      include: 'approvers',
      filter_approver: 'for-approval-user',
    });

    const newObj = {
      q: '',
      page: 1,
      sort_by: '',
      per_page: 5,
      order_by: 'status',
      ['include[]']: 'approvers',
      filter_approver: 'for-approval-user',
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const tryCatch = async (params, filter_approver = filter?.filter_approver) => {
    if (filter_approver === 'for-approval-user') {
      setApprovalData([]);
    }

    if (filter_approver === 'done') {
      setDoneData([]);
    }

    try {
      const response = await dispatch(getRequest(params));

      const newData = response?.payload?.data?.map((dd) => {
        const approverArr = dd?.relationships?.approvers?.data.map((app) => {
          return response?.payload?.included.find((id) => +id?.id === +app?.id);
        });

        return {
          ...dd,
          id: dd?.id,
          itemCode: dd?.id,
          approver: approverArr,
          image: dd?.attributes?.image,
          status: dd?.attributes?.status,
          itemName: dd?.attributes?.item_name,
          stockNeeded: dd?.attributes?.quantity,
          borrowedBy: dd?.attributes?.borrowed_by,
          dateRequested: dd?.attributes?.created_at,
          typeOfTransaction: dd?.attributes?.transaction_type,
        };
      });

      if (filter_approver === 'for-approval-user') {
        console.log('YAWAAAAA', newData);
        setApprovalData(newData);
      }

      if (filter_approver === 'done') {
        setDoneData(newData);
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleRequest = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      // order_by: filter?.order_by,
      ['include[]']: filter?.include,
      filter_approver: filter?.filter_approver,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page, filter_approver = '' }) => {
    setFilter((p) => ({
      ...p,
      page: page,
    }));

    const newObj = {
      page: page,
      q: filter?.q,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      ['include[]']: filter?.include,
      filter_approver: filter_approver !== '' ? filter_approver : filter?.filter_approver,
    };

    const params = objectCleaner(newObj);

    const _type = filter_approver !== '' ? filter_approver : filter?.filter_approver;

    tryCatch(params, _type);
  };

  useEffect(() => {
    handleRequest();
  }, []);

  console.log('ApprovalData', approvalData);

  return (
    <PrivateLayout pageTitle='Item Request'>
      <div className={styles?.inventory}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          setSelectTab('for-approval-user');
                          setFilter((p) => ({
                            ...p,
                            filter_approver: 'for-approval-user',
                          }));
                          handlePaginationClick({ page: 1, filter_approver: 'for-approval-user' });
                        }}
                        className={`${selectTab === 'for-approval-user' ? styles?.active : ''}`}
                      >
                        Approval
                      </Button>
                    </div>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => {
                          setSelectTab('done');
                          setFilter((p) => ({
                            ...p,
                            filter_approver: 'done',
                          }));
                          handlePaginationClick({ page: 1, filter_approver: 'done' });
                        }}
                        className={`${selectTab === 'done' ? styles?.active : ''}`}
                      >
                        Done
                      </Button>
                    </div>
                  </div>

                  <div className={styles?.filter}>
                    <div className={styles?.selectContainer}>
                      <Form.Group className={styles?.searchBar}>
                        <GlassSVG />
                        <FormControl
                          name='search'
                          placeholder='Search'
                          value={filter?.search}
                          onChange={handleSearch}
                        />
                      </Form.Group>

                      <Form.Select
                        name='sort_by'
                        onChange={handleSort}
                        value={filter?.sort_by}
                        className={styles?.select}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='ASC'>Pending</option>
                        <option value='DESC'>Approved</option>
                      </Form.Select>
                    </div>

                    <Button className={styles?.approveAll} onClick={showActivateModal}>
                      <CheckApproveSVG />
                      Approve All
                    </Button>
                  </div>
                </div>

                <div className={styles?.body}>
                  <ConditionalRender
                    condition={selectTab === 'for-approval-user'}
                    renderIf={
                      <ApprovalTab
                        data={approvalData}
                        setFilter={setFilter}
                        setShowRequest={setShowRequest}
                        setShowApprover={setShowApprover}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />

                  <ConditionalRender
                    condition={selectTab === 'done'}
                    renderIf={
                      <DoneTab
                        data={doneData}
                        setFilter={setFilter}
                        setShowRequest={setShowRequest}
                        setShowApprover={setShowApprover}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmModal
        show={showConfirm?.show}
        title={showConfirm?.title}
        handleClose={handleCloseConfirm}
        handleConfirm={handleApproveAll}
      />

      <Approver show={showApprover?.show} data={showApprover?.data} handleClose={closeApprover} />
      <ViewRequest show={showRequest?.show} data={showRequest?.data} handleClose={closeRequest} />
    </PrivateLayout>
  );
};

export default ItemRequest;
