import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from 'reducers/authReducer';
import { usersReducer } from 'reducers/usersReducer';
import { rolesReducer } from 'reducers/rolesReducer';
import { sampleReducer } from 'reducers/sampleReducer';
import { requestReducer } from 'reducers/requestReducer';
import { approverReducer } from 'reducers/approverReducer';
import { inventoryReducer } from 'reducers/inventoryReducer';
import { auditLogsReducer } from 'reducers/auditLogsReducer';
import { permissionsReducer } from 'reducers/permissionsReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    roles: rolesReducer,
    sample: sampleReducer,
    request: requestReducer,
    approver: approverReducer,
    inventory: inventoryReducer,
    auditLogs: auditLogsReducer,
    permissions: permissionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
