/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import ConditionalRender from 'components/ConditionalRender';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import ConfirmModal from './components/Modals/Confirm/ConfirmModal';
import { checkStringPermission } from 'helpers/filteredPermissions';
import AddApprover from './components/Modals/AddApprover/AddApprover';
import {
  getApprovers,
  selectApprover,
  updateApprover,
  deleteApprover,
} from 'reducers/approverReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as DragSVG } from 'assets/svg/drag.svg';
import { ReactComponent as CircleSVG } from 'assets/svg/circle.svg';
import { ReactComponent as BentArrow } from 'assets/svg/bent arrow.svg';
import { ReactComponent as CloseSVG } from 'assets/svg/closeButton.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';
import { ReactComponent as UserCheckSVG } from 'assets/svg/userCheckl.svg';

// Main component
const Approver = () => {
  const dispatch = useDispatch();

  const approverState = useSelector(selectApprover);
  const { approvers } = approverState;

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const [data, setData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);

  const [isHolding, setIsHolding] = useState(false);

  const [filter, setFilter] = useState({
    sort_by: '',
    per_page: 100,
  });

  const [showConfirm, setShowConfirm] = useState({
    data: '',
    title: '',
    show: false,
  });

  const handleCheck = (e) => async (formData) => {
    const { checked } = e.target;

    const objData = {
      params: {
        is_required: checked,
      },
      id: formData?.id,
    };

    try {
      const response = await dispatch(updateApprover(objData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      dispatch(getApprovers({ per_page: 100 }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleMouseDown = () => {
    setIsHolding(true);
  };

  const handleMouseUp = () => {
    setIsHolding(false);
  };

  const handleSort = async () => {
    // eslint-disable-next-line prefer-const
    let _data = [...data];

    const draggedItemContent = _data.splice(dragItem.current, 1)[0];

    _data.splice(dragOverItem.current, 0, draggedItemContent);

    // API HERE
    const objData = {
      params: {
        order: +dragOverItem.current + 1,
      },
      id: draggedItemContent?.id,
    };

    // eslint-disable-next-line no-unreachable
    try {
      const response = await dispatch(updateApprover(objData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      dispatch(getApprovers({ per_page: 100 }));
    } catch (error) {
      toast.error(error);
    }

    dragItem.current = null;
    dragOverItem.current = null;
  };

  const handleCloseConfirm = () => {
    setShowConfirm({
      data: '',
      title: '',
      show: false,
    });
  };

  const handleDelete = async () => {
    try {
      const response = await dispatch(deleteApprover(showConfirm?.data?.id));

      if (response) {
        refreshData();
        toast.info(`Successfully Deleted User`);
        handleCloseConfirm();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const refreshData = () => {
    const newObj = { per_page: filter?.per_page, sort_by: filter?.sort_by };
    const params = objectCleaner(newObj);

    dispatch(getApprovers(params));
  };

  const handleSortBy = (e) => {
    const { name, value } = e.target;

    const _data = [...data];

    if (value === 'ASC') {
      _data.sort((a, b) => {
        return a.attributes?.order - b.attributes?.order;
      });
    } else {
      _data.sort((a, b) => {
        return b.attributes?.order - a.attributes?.order;
      });
    }

    setData(_data);
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (approvers && approvers.length > 0) {
      const _approvers = [...approvers];

      _approvers.sort((a, b) => {
        return a.attributes?.order - b.attributes?.order;
      });

      setData(_approvers);
    }
  }, [approvers]);

  useEffect(() => {
    const newObj = { per_page: filter?.per_page, sort_by: filter?.sort_by };
    const params = objectCleaner(newObj);

    dispatch(getApprovers(params));
  }, []);

  const canAdd = checkStringPermission('can create approver');
  const canUpdate = checkStringPermission('can update approver');
  const canDelete = checkStringPermission('can delete approver');

  return (
    <PrivateLayout pageTitle='Approver'>
      <div className={styles?.approver}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>Approver</h5>
                  </div>

                  <div className={styles?.filter}>
                    <div className={styles?.selectContainer}>
                      <Form.Select
                        name='sort_by'
                        onChange={handleSortBy}
                        className={styles?.select}
                        value={filter?.sort_by}
                      >
                        <option value='' hidden>
                          Sort By
                        </option>
                        <option value='ASC'>Ascending</option>
                        <option value='DESC'>Descending</option>
                      </Form.Select>
                    </div>

                    <ConditionalRender
                      condition={canAdd}
                      renderIf={
                        <Button
                          className={styles?.addApprover}
                          onClick={() => {
                            setShowAdd(true);
                          }}
                        >
                          <CirclePlus />
                          Add Approver
                        </Button>
                      }
                    />
                  </div>
                </div>

                <div className={styles?.body}>
                  <div className={styles?.lineContainer}>
                    {data &&
                      data.length >= 1 &&
                      data.map((dd, index) =>
                        index === 0 ? (
                          <div key={index} className={styles?.circleContainer}>
                            <CircleSVG className={styles?.cirle} />
                          </div>
                        ) : (
                          <div
                            key={index}
                            className={`${styles?.arrowContainer} ${
                              index === 1 ? styles?.short : ''
                            }`}
                          >
                            <BentArrow className={styles?.arrow} />
                          </div>
                        ),
                      )}
                  </div>

                  <div className={styles?.cardContainer}>
                    {data &&
                      data.length >= 1 &&
                      data.map((dd, index) => (
                        <div
                          draggable
                          key={index}
                          onDragEnd={handleSort}
                          className={styles?.card}
                          onDragOver={(e) => e.preventDefault()}
                          onDragStart={(e) => (dragItem.current = index)}
                          onDragEnter={(e) => (dragOverItem.current = index)}
                        >
                          <ConditionalRender
                            condition={canDelete}
                            renderIf={
                              <CloseSVG
                                onClick={() => {
                                  setShowConfirm({
                                    data: dd,
                                    title: 'Confirm Delete?',
                                    show: true,
                                  });
                                }}
                                className={styles?.close}
                              />
                            }
                          />

                          <div
                            onMouseUp={handleMouseUp}
                            onMouseDown={handleMouseDown}
                            className={`${styles?.drag} ${isHolding ? styles?.grabbing : ''}`}
                          >
                            <DragSVG />
                          </div>

                          <div className={styles?.info}>
                            <p>
                              <UserCheckSVG /> {dd?.attributes?.role_name}
                            </p>

                            <Form.Check
                              type='switch'
                              disabled={canUpdate === false}
                              onChange={(e) => handleCheck(e)(dd)}
                              checked={dd?.attributes?.is_required}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <AddApprover show={showAdd} setShowAdd={setShowAdd} refreshData={refreshData} />
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmModal
        show={showConfirm?.show}
        handleUsers={refreshData}
        title={showConfirm?.title}
        handleConfirm={handleDelete}
        handleClose={handleCloseConfirm}
      />
    </PrivateLayout>
  );
};

export default Approver;
