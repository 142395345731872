/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Modal } from 'react-bootstrap';

import { addPermission, removePermission, selectRoles } from 'reducers/rolesReducer';

import './AssignModal.scss';

const AssignModal = ({ show, handleClose, data, editData, setRefreshRole }) => {
  console.log('dataaaaaa', data);
  const dispatch = useDispatch();

  const userState = useSelector(selectRoles);
  const { addPermissionLogs, removePermissionLogs } = userState;

  const [permissions, setPermissions] = useState([]);

  const checkedPermission = (tableData) => {
    if (!data?.attributes?.permissions) return;

    const permissions = data?.attributes?.permissions;

    const result = permissions.filter((dd) => dd === tableData);

    if (result.length > 0) {
      console.log('true');
      return true;
    }

    console.log('false');
    return false;
  };

  const handleCheckChange = async (e, permission) => {
    const { checked } = e.target;

    if (checked) {
      const parameters = {
        id: data?.id,
        params: { permissions: permission },
      };

      try {
        const response = await dispatch(addPermission(parameters));

        if (response?.meta?.requestStatus === 'rejected') {
          toast.error(response?.payload?.errors[0]?.detail);
          return;
        }

        if (response) {
          editData((prev) => ({ ...prev, data: response?.payload?.data }));
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      const parameters = {
        id: data?.id,
        params: { permissions: permission },
      };

      try {
        const response = await dispatch(removePermission(parameters));

        if (response?.meta?.requestStatus === 'rejected') {
          toast.error(response?.payload?.errors[0]?.detail);
          return;
        }

        if (response) {
          editData((prev) => ({ ...prev, data: response?.payload?.data }));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    const getPermissions = async () => {
      try {
        axios
          .get('api/permissions', {
            params: {
              per_page: 1000,
            },
          })
          .then((response) => {
            setPermissions(response?.data?.data);
          });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getPermissions();
  }, []);

  return (
    <Modal
      centered
      show={show}
      className='assignPermission'
      onHide={() => {
        handleClose();
        setRefreshRole(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <Row className='w-100'>
            <Col className='header'>
              <p>
                User Roles <span>{data?.attributes?.name}</span>
              </p>
            </Col>
          </Row>

          <Row className='w-100'>
            <Col>
              <Form.Group className='permissions'>
                <Form.Label>Permission</Form.Label>

                {permissions &&
                  permissions.length >= 1 &&
                  permissions.map((dd, index) => {
                    return (
                      <div className='list-data' key={index}>
                        <p>{dd?.attributes?.name}</p>

                        <Form.Check
                          type='switch'
                          className={'switchPermission'}
                          checked={checkedPermission(dd?.attributes?.name)}
                          disabled={addPermissionLogs?.isLoading || removePermissionLogs?.isLoading}
                          onChange={(e) => {
                            handleCheckChange(e, dd?.attributes?.name);
                          }}
                        />
                      </div>
                    );
                  })}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignModal;
