/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';

import { updateRoles, selectRoles } from 'reducers/rolesReducer';

import './RolesModal.scss';

const RolesModal = ({ show, handleClose, data, handleRoles }) => {
  const dispatch = useDispatch();

  const rolesState = useSelector(selectRoles);
  const { uploadLogs } = rolesState;

  const [formData, setFormData] = useState({
    roles: [],
    userRoles: '',
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSave = async () => {
    if (formData?.userRoles === '') {
      toast.error('Please enter role name');
      return;
    }

    const objData = {
      id: data?.id,
      params: { name: formData?.userRoles },
    };

    try {
      const response = await dispatch(updateRoles(objData));

      if (response) {
        handleClose();
        handleRoles();
        toast.info('Successfully updated roles');
      }

      setFormData((prev) => ({ ...prev, userRoles: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (data && show) {
      setFormData((prev) => ({
        ...prev,
        userRoles: data?.attributes?.name,
      }));
    }
  }, [data, show]);

  return (
    <Modal show={show} onHide={handleClose} centered className='updateRoles'>
      <Modal.Header closeButton>
        <Modal.Title>Update Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <Row className='w-100'>
            <Col>
              <Form.Group className='mb-4 group'>
                <Form.Label>Roles</Form.Label>
                <Form.Control
                  name='userRoles'
                  onChange={handleChange}
                  placeholder='Enter Here'
                  value={formData?.userRoles}
                />
              </Form.Group>

              <Form.Group className='group'>
                <Button
                  onClick={handleSave}
                  className='updateRoles'
                  disabled={uploadLogs?.isLoading}
                >
                  Update Role
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RolesModal;
