/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getInventory, addItem, requestItem, getItemDetails } from './thunks/inventoryThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  logs: initialLog,
  addLogs: initialLog,
  requestLogs: initialLog,

  itemDetails: null,
  itemLogs: initialLog,
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: {
    [getInventory.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getInventory.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getInventory.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [addItem.fulfilled]: (state, action) => {
      state.addLogs.isSuccess = true;
      state.addLogs.isLoading = false;
    },
    [addItem.pending]: (state) => {
      state.addLogs.error = '';
      state.addLogs.isFailed = false;
      state.addLogs.isLoading = true;
      state.addLogs.isSuccess = false;
    },
    [addItem.rejected]: (state, action) => {
      state.addLogs.isFailed = true;
      state.addLogs.isLoading = false;
      state.addLogs.error = action?.payload?.errors[0]?.detail;
    },
    [requestItem.fulfilled]: (state, action) => {
      state.requestLogs.isSuccess = true;
      state.requestLogs.isLoading = false;
    },
    [requestItem.pending]: (state) => {
      state.requestLogs.error = '';
      state.requestLogs.isFailed = false;
      state.requestLogs.isLoading = true;
      state.requestLogs.isSuccess = false;
    },
    [requestItem.rejected]: (state, action) => {
      state.requestLogs.isFailed = true;
      state.requestLogs.isLoading = false;
      state.requestLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getItemDetails.fulfilled]: (state, action) => {
      state.itemLogs.isSuccess = true;
      state.itemLogs.isLoading = false;
      state.itemDetails = action.payload.data;
    },
    [getItemDetails.pending]: (state) => {
      state.itemLogs.error = '';
      state.itemLogs.isFailed = false;
      state.itemLogs.isLoading = true;
      state.itemLogs.isSuccess = false;
    },
    [getItemDetails.rejected]: (state, action) => {
      state.itemLogs.isFailed = true;
      state.itemLogs.isLoading = false;
      state.itemLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: inventoryActions, reducer: inventoryReducer } = slice;

export const { updateUserinfo } = inventoryActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.inventory || initialState;

export const selectInventory = createSelector([selectDomain], (inventoryState) => inventoryState);

/* --------------------------------------------------------------------------------- */

export { getInventory, addItem, requestItem, getItemDetails };
