/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUsers = createAsyncThunk('getUsers', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/users`,
    params: params,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const approveUser = createAsyncThunk(
  'approveUser',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/admin/creator/approval/${id}`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const declineUser = createAsyncThunk(
  'declineUser',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/admin/creator/decline/${id}`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateUser = createAsyncThunk(
  'updateUser',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `api/users/${id}`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const addUser = createAsyncThunk('addUser', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: `api/users`,
    data: params,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const deleteUser = createAsyncThunk('deleteUser', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `api/users/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getProfile = createAsyncThunk('getProfile', async ({}, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/profile`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `/api/user/${id}/edit`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const editUser = createAsyncThunk(
  'editUser',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/user/${id}/edit`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
