/* eslint-disable multiline-comment-style */
import React from 'react';
import { Pagination } from 'react-bootstrap';

import '../Styles/TablePagination.scss';

const SamplePagination = () => {
  return (
    <div className='table-pagination'>
      <p>Showing page 1 of 3</p>

      <Pagination>
        <Pagination.First className='box' />

        <Pagination.Prev className='box' />

        <Pagination.Item>1</Pagination.Item>

        <Pagination.Item active>2</Pagination.Item>

        <Pagination.Item>3</Pagination.Item>

        <Pagination.Next className='box' />

        <Pagination.Last className='box' />
      </Pagination>
    </div>
  );
};

export default SamplePagination;
