/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Modal, Form } from 'react-bootstrap';

import { getApprovers } from 'reducers/approverReducer';

import './index.scss';
import { ReactComponent as CircleSVG } from 'assets/svg/circle.svg';
import { ReactComponent as WaitingSVG } from 'assets/svg/waiting.svg';
import { ReactComponent as BentArrow } from 'assets/svg/bent arrow.svg';
import { ReactComponent as ApprovedSVG } from 'assets/svg/approved.svg';
import { ReactComponent as DeclinedSVG } from 'assets/svg/declined.svg';
import { ReactComponent as UserCheckSVG } from 'assets/svg/userCheckl.svg';

const Approver = ({ show, data = [], handleClose }) => {
  const dispatch = useDispatch();

  const [approvers, setApprovers] = useState([]);

  useEffect(() => {
    const apiApprovers = async () => {
      const params = { per_page: '100' };
      const response = await dispatch(getApprovers(params));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        setApprovers(response?.payload?.data?.data);
      }
    };

    apiApprovers();
  }, []);

  console.log('approvers', approvers);
  console.log('data', data);

  return (
    <Modal show={show} onHide={handleClose} centered className='approver'>
      <Modal.Header closeButton>
        <Modal.Title>Approver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <div className='lineContainer'>
            {approvers &&
              approvers.length >= 1 &&
              approvers.map((dd, index) =>
                index === 0 ? (
                  <div key={index} className='circleContainer'>
                    <CircleSVG className='cirle' />
                  </div>
                ) : (
                  <div key={index} className={`arrowContainer ${index === 1 ? 'short' : ''}`}>
                    <BentArrow className='arrow' />
                  </div>
                ),
              )}
          </div>

          {data && (
            <div className='cardContainer'>
              {approvers &&
                approvers.length >= 1 &&
                approvers.map((dd, index) => {
                  const selectedData =
                    data?.find((d) => d?.attributes?.user_role === dd?.attributes?.role_name) ||
                    null;

                  const finalData = selectedData ? selectedData : dd;

                  console.log('finalData', finalData);

                  return (
                    <div key={index} className='card'>
                      <div className={`drag ${finalData?.attributes?.status}`}>
                        {selectedData ? (
                          <>
                            {selectedData?.attributes?.status === 'rejected' && <DeclinedSVG />}
                            {selectedData?.attributes?.status === 'approved' && <ApprovedSVG />}
                          </>
                        ) : (
                          <WaitingSVG />
                        )}
                      </div>

                      <div className='info'>
                        <p>
                          <UserCheckSVG />{' '}
                          {finalData?.attributes?.user_role || finalData?.attributes?.role_name}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Approver;
