/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Button } from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUIs/ImageUI';
import { getUsersState } from 'reducers/usersReducer';
import Decline from './components/Modals/Decline/Decline';
import AddRoles from './components/Modals/AddRoles/AddRoles';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';

// Assets
import styles from './PendingTab.module.scss';
import placeholderProfile from 'assets/images/avatar.jpg';

// Main component
const PendingTab = ({ canApprove, setFilter, data, handleUsers, handlePaginationClick }) => {
  const usersDataList = useSelector(getUsersState);

  const { meta, logs } = usersDataList;

  const [showAddModal, setShowAddRole] = useState({
    show: false,
    data: null,
  });

  const [showDeclineModal, setShowDeclineModal] = useState({
    show: false,
    data: null,
  });

  const handleCloseUpdate = () => {
    setShowAddRole((prev) => ({ ...prev, show: false, data: null }));
  };

  const handleDecline = () => {
    setShowDeclineModal((prev) => ({ ...prev, show: false, data: null }));
  };

  const handleAddRole = (viewData) => {
    setShowAddRole((prev) => {
      return {
        ...prev,
        data: viewData,
        show: true,
      };
    });
  };

  const handleDeclineAccount = (viewData) => {
    setShowDeclineModal((prev) => {
      return {
        ...prev,
        data: viewData,
        show: true,
      };
    });
  };

  return (
    <>
      <Table responsive className={styles?.processTable}>
        <thead>
          <tr>
            <th>Image</th>
            <th>User ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Date Added</th>
            <th>Status</th>
            <ConditionalRender condition={canApprove} renderIf={<th>Action</th>} />
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length >= 1 &&
            data.map((dd) => (
              <tr key={dd?.id}>
                <td>
                  <ImageUI src={dd?.image} alt={dd?.name} fallbackSrc={placeholderProfile} />
                </td>
                <td>{dd?.userID}</td>
                <td>{dd?.name}</td>
                <td>{dd?.email}</td>
                <td>{dd?.dateAdded}</td>
                <td>
                  <span
                    className={styles[`${dd?.attributes?.is_approved === false ? 'Pending' : ''}`]}
                  >
                    {dd?.attributes?.is_approved === true ? (
                      <>Approving</>
                    ) : (
                      <>{dd?.attributes?.is_approved === false ? 'Pending' : 'Declined'}</>
                    )}
                  </span>
                </td>
                <ConditionalRender
                  condition={canApprove}
                  renderIf={
                    <td>
                      <div className={styles?.tableButtons}>
                        {dd?.attributes?.is_approved === false &&
                          dd?.attributes?.is_declined === false && (
                            <>
                              <Button
                                onClick={() => handleAddRole(dd)}
                                className={styles?.activate}
                              >
                                Approve
                              </Button>
                              <Button
                                onClick={() => handleDeclineAccount(dd)}
                                className={styles?.deactivate}
                              >
                                Decline
                              </Button>
                            </>
                          )}

                        {dd?.attributes?.is_declined === true && (
                          <Button onClick={() => handleAddRole(dd)} className={styles?.activate}>
                            Re-Approve
                          </Button>
                        )}
                      </div>
                    </td>
                  }
                />
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={10}>
              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </td>
          </tr>
        </tfoot>
      </Table>

      <AddRoles
        handleUsers={handleUsers}
        data={showAddModal?.data}
        show={showAddModal?.show}
        handleClose={handleCloseUpdate}
      />

      <Decline
        handleUsers={handleUsers}
        handleClose={handleDecline}
        data={showDeclineModal?.data}
        show={showDeclineModal?.show}
      />
    </>
  );
};

export default PendingTab;
