import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';

import './ConfirmModal.scss';

const ConfirmModal = ({ show, title, handleClose, handleConfirm, confirmLoading }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className='confirmAction'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          <Row className='w-100'>
            <Col>
              <div className='buttonContainer'>
                <Button onClick={handleConfirm} className='updateRoles' disabled={confirmLoading}>
                  Confirm
                </Button>

                <Button onClick={handleClose} className='cancel'>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
