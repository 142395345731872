/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import ModalView from './Modal';
import Approver from './Approver';
import { selectRequest } from 'reducers/requestReducer';
import TablePagination from 'components/GlobalUIs/Pagination';

import styles from './ReturnedItems.module.scss';

const ReturnedItems = ({ data, setFilter, handlePaginationClick }) => {
  const requestState = useSelector(selectRequest);
  const { meta, logs } = requestState;

  const [showModal, setShowModal] = useState({
    show: false,
    data: null,
    title: 'Returned Item',
  });

  const [showApprover, setShowApprover] = useState({
    data: null,
    show: false,
  });

  const handleClose = () => {
    setShowModal((prev) => ({ ...prev, show: false, data: null }));
  };

  const handleView = (viewData) => {
    setShowModal((prev) => {
      return {
        ...prev,
        data: viewData,
        show: true,
      };
    });
  };

  const closeApprover = () => {
    setShowApprover({
      data: null,
      show: false,
    });
  };

  return (
    <>
      <Table responsive className={styles?.returnTable}>
        <thead>
          <tr>
            <th>Image</th>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Date of Requested</th>
            <th>Borrowed By</th>
            <th>Stock Needed</th>
            <th>Approver</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length >= 1 &&
            data.map((dd) => (
              <tr key={dd?.id}>
                <td>
                  <Image src={dd?.attributes?.image} />
                </td>
                <td>{dd?.itemCode}</td>
                <td>{dd?.itemName}</td>
                <td>{dd?.dateRequested}</td>
                <td>{dd?.borrowedBy}</td>
                <td>{dd?.stockNeeded}</td>
                <td>
                  <Button
                    onClick={() => {
                      setShowApprover({
                        show: true,
                        data: dd?.approver,
                      });
                    }}
                    className={styles?.button}
                  >
                    Approver
                  </Button>
                </td>
                <td>
                  <span className={styles[`${dd?.attributes?.status}`]}>
                    {dd?.attributes?.status}
                  </span>
                </td>
                <td>
                  <Button onClick={() => handleView(dd)} className={styles?.view}>
                    View
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={10}>
              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </td>
          </tr>
        </tfoot>
      </Table>

      <ModalView
        data={showModal?.data}
        show={showModal?.show}
        title={showModal?.title}
        handleClose={handleClose}
      />

      <Approver show={showApprover?.show} data={showApprover?.data} handleClose={closeApprover} />
    </>
  );
};

export default ReturnedItems;
