/* eslint-disable no-unused-vars */

// Core & Vendor Packages
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Image, Row, Col, Dropdown } from 'react-bootstrap';

// Components
import { logout } from 'reducers/authReducer';
import { selectAuth } from 'reducers/authReducer';
import ImageUI from 'components/GlobalUIs/ImageUI';
import { checkStringPermission } from 'helpers/filteredPermissions';

// Helpers
import { removeItem } from 'utils/storage';

// Assets
import 'components/Styles/Navbar.scss';
import AvatarLogo from 'assets/images/logo.png';
import { ReactComponent as AclSVG } from 'assets/svg/acl.svg';
import { ReactComponent as BellSVG } from 'assets/svg/bell.svg';
import placeholderProfile from 'assets/images/blank-profile.png';
import { ReactComponent as UsersSVG } from 'assets/svg/users.svg';
import { ReactComponent as ApproverSVG } from 'assets/svg/approver.svg';
import { ReactComponent as DashboardSVG } from 'assets/svg/dashboard.svg';
import { ReactComponent as InventorySVG } from 'assets/svg/inventory.svg';
import { ReactComponent as AuditTrailSVG } from 'assets/svg/audit trail.svg';
import { ReactComponent as ReportsSVG } from 'assets/svg/reports navbar.svg';
import { ReactComponent as ItemRequestSVG } from 'assets/svg/itemRequest.svg';
import { ReactComponent as TransactionsSVG } from 'assets/svg/transactions.svg';

// Main Component
const NavBar = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const logOut = () => {
    // eslint-disable-next-line multiline-comment-style
    setTimeout(() => {
      dispatch(logout());
      navigate('/');
    }, 1000);
  };

  const navList = [
    { id: 1, name: 'Dashboard', link: '/', svg: DashboardSVG, permission: '' },
    {
      id: 2,
      name: 'Inventory',
      svg: InventorySVG,
      link: '/inventory',
      permission: 'can view inventory module',
    },
    {
      id: 3,
      svg: ItemRequestSVG,
      name: 'Item Request',
      link: '/item-request',
      permission: 'can view item request module',
    },
    {
      id: 4,
      name: 'Transactions',
      svg: TransactionsSVG,
      link: '/transactions',
      permission: 'can view transactions module',
    },
    {
      id: 5,
      name: 'Approver',
      svg: ApproverSVG,
      link: '/approver',
      permission: 'can view approver module',
    },
    {
      id: 6,
      name: 'Reports',
      link: '/reports',
      svg: ReportsSVG,
      permission: 'can view reports module',
    },
    {
      id: 7,
      svg: AclSVG,
      name: 'Inventory Staff',
      link: '/inventory-staff',
      permission: 'can view inventory staff module',
    },
    {
      id: 8,
      svg: AuditTrailSVG,
      name: 'Audit Trail',
      link: '/audit-trail',
      permission: 'can view audit module',
    },
    { id: 9, name: 'Users', link: '/users', svg: UsersSVG, permission: 'can view user module' },
    { id: 10, name: 'ACL', link: '/acl', svg: AclSVG, permission: 'can view acl module' },
  ];

  return (
    <>
      <div className='top-bar shadow-sm'>
        {navList.map(({ svg: Svg, id, link, name, permission }) => {
          const result = checkStringPermission(permission);

          if (!result && name !== 'Dashboard') return null;
          return (
            <Link key={id} to={link} className={`nav-link ${pathname === link ? 'active' : ''}`}>
              <Svg className='link-icon' />
              {name}
            </Link>
          );
        })}
      </div>

      <div className='background-gray'>
        <Container>
          <Row>
            <Col>
              <div className='dashboard-account'>
                <div className='left-container'>
                  <Image src={AvatarLogo} />

                  <div className='line'></div>

                  <p>
                    Concepcion Municipal <span>Inventory</span>
                  </p>
                </div>

                <div className='right-container'>
                  <BellSVG className='bell' />

                  <Dropdown>
                    <Dropdown.Toggle variant='success' className='account-settings'>
                      <ImageUI
                        fallbackSrc={placeholderProfile}
                        alt={userInfo?.attributes?.last_name}
                        src={userInfo?.attributes?.profile_pic}
                      />

                      {userInfo?.attributes?.first_name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='selections'>
                      <Dropdown.Item
                        className={`${pathname === '/user-account' ? 'active' : ''}`}
                        onClick={() => navigate('/user-account')}
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`${pathname === '/change-password' ? 'active' : ''}`}
                        onClick={() => navigate('/change-password')}
                      >
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NavBar;
