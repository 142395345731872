/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import { checkEmpty } from 'utils/validator';
import { editUser, getUsersState } from 'reducers/usersReducer';
import ImageUpload from 'components/GlobalUIs/ImageUpload';

import styles from './ShowUser.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

const ShowUser = ({ show = false, setShowView, handleUsers, viewData }) => {
  const dispatch = useDispatch();

  const userState = useSelector(getUsersState);
  const { editLogs } = userState;

  const [data, setData] = useState({
    id: '',
    roles: [],
    status: '',
    lastname: '',
    position: '',
    firstname: '',
    userImage: '',
    emailAddress: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    const emptyCheck = checkEmpty(data, ['roles']);

    if (emptyCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new FormData();

    formData.append('role', data?.position);
    formData.append('status', data?.status);
    formData.append('last_name', data?.lastname);
    formData.append('first_name', data?.firstname);

    if (viewData?.email !== data?.emailAddress) {
      formData.append('email', data?.emailAddress);
    }

    if (typeof data?.userImage !== 'string') {
      formData.append('profile_pic', data?.userImage[0]);
    }

    try {
      const response = await dispatch(editUser({ params: formData, id: data?.id }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        setData((p) => ({
          ...p,
          id: '',
          status: '',
          lastname: '',
          position: '',
          firstname: '',
          userImage: '',
          emailAddress: '',
        }));

        handleUsers();

        setShowView({
          show: false,
          data: null,
        });

        toast.success('Successfully Updated user');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        axios.get('api/roles').then((response) => {
          setData((prev) => ({ ...prev, roles: response?.data?.data }));
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getRoles();
  }, []);

  useEffect(() => {
    if (viewData) {
      setData((prev) => {
        return {
          ...prev,
          id: viewData?.id,
          status: viewData?.status,
          userImage: viewData?.image,
          position: viewData?.position,
          emailAddress: viewData?.email,
          lastname: viewData?.attributes?.last_name,
          firstname: viewData?.attributes?.first_name,
        };
      });
    }
  }, [viewData]);

  console.log('data', data);

  return (
    <div className={`${styles?.addProcess} ${show ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>View User</p>

        <CloseSVG
          className={styles?.close}
          onClick={() => {
            setShowView({
              data: null,
              show: false,
            });
          }}
        />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.group}>
          <Form.Label>First Name</Form.Label>
          <FormControl
            name='firstname'
            value={data?.firstname}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Last Name</Form.Label>
          <FormControl
            name='lastname'
            value={data?.lastname}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Email Address</Form.Label>
          <FormControl
            name='emailAddress'
            value={data?.emailAddress}
            onChange={handleChange}
            placeholder='Enter here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>User Role</Form.Label>

          <Form.Select name='position' value={data?.position} onChange={handleChange}>
            <option value='' hidden>
              Select Role
            </option>
            {data &&
              data?.roles &&
              data?.roles.length >= 1 &&
              data.roles?.map((dd) => (
                <option key={dd?.id} value={dd?.attributes?.name}>
                  {dd?.attributes?.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>User Image</Form.Label>

          <ImageUpload name='userImage' setState={setData} stateValue={data?.userImage} />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Status</Form.Label>

          <div className={styles?.buttons}>
            <Button
              onClick={() => {
                setData((prev) => {
                  return {
                    ...prev,
                    status: 'active',
                  };
                });
              }}
              className={`${data?.status === 'active' ? styles?.active : ''}`}
            >
              ACTIVE
            </Button>
            <Button
              onClick={() => {
                setData((prev) => {
                  return {
                    ...prev,
                    status: 'inactive',
                  };
                });
              }}
              className={`${data?.status === 'inactive' ? styles?.active : ''}`}
            >
              INACTIVE
            </Button>
          </div>
        </Form.Group>

        <Button
          onClick={handleSubmit}
          disabled={editLogs?.isLoading}
          className={styles?.processButton}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ShowUser;
