/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  login,
  signup,
  forgotPassword,
  updatePassword,
  updateProfileData,
} from './thunks/authThunks';
import { setAndStringify, setItem, getItem, getParsedItem, clearAll } from 'utils/storage';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  token: getItem('accessToken') ?? null,
  userInfo: getParsedItem('userinfo') ?? null,
  permissions: getParsedItem('permissions') ?? [],
  isAuthenticated: getParsedItem('isAuthenticated') ?? false,

  loginLogs: initialLog,
  signUpLogs: initialLog,
  forgotPassLogs: initialLog,
  updateProfileLogs: initialLog,
  updatePasswordLogs: initialLog,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.userInfo = null;
      state.isAuthenticated = false;
      state.loginLogs = initialLog;
      state.permissions = [];

      clearAll();
    },
    setIsAuthenticated: (state) => {
      console.log('running');
      state.isAuthenticated = true;
      setItem('isAuthenticated', true);
    },
    updateUserinfo: (state, action) => {
      state.userInfo = action?.payload;
      setAndStringify('userinfo', action.payload);
    },
    resetForgotState: (state) => {
      state.forgotPassLogs = initialLog;
    },
    resetSignUpState: (state) => {
      state.signUpLogs = initialLog;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.loginLogs.isSuccess = true;
      state.loginLogs.isLoading = false;
      state.token = action.payload.data.token;
      state.userInfo = action.payload.data.data;
      state.permissions = action.payload.data.data?.attributes?.permissions;
      // Set Data to sessionStorage
      setItem('accessToken', action.payload.data.token);
      setAndStringify('userinfo', action.payload.data.data);
      setAndStringify('permissions', action.payload.data.data?.attributes?.permissions);
    },
    [login.pending]: (state) => {
      state.loginLogs.error = '';
      state.loginLogs.isFailed = false;
      state.loginLogs.isLoading = true;
      state.loginLogs.isSuccess = false;
    },
    [login.rejected]: (state, action) => {
      state.token = null;
      state.loginLogs.isFailed = true;
      state.loginLogs.isLoading = false;

      if (action?.payload?.errors) {
        state.loginLogs.error = action?.payload?.errors[0]?.detail;
      } else {
        state.loginLogs.error = action?.payload?.message[0]?.detail;
      }
    },
    [updateProfileData.fulfilled]: (state, action) => {
      console.log('action', action);
      state.loginLogs.isSuccess = true;
      state.loginLogs.isLoading = false;
      state.userInfo = action.payload.data;
      // Set Data to sessionStorage
      setAndStringify('userinfo', action.payload.data);
    },
    [updateProfileData.pending]: (state) => {
      state.loginLogs.error = '';
      state.loginLogs.isFailed = false;
      state.loginLogs.isLoading = true;
      state.loginLogs.isSuccess = false;
    },
    [updateProfileData.rejected]: (state, action) => {
      state.loginLogs.error = action?.payload?.message[0]?.detail;
      state.loginLogs.isLoading = false;
      state.loginLogs.isFailed = true;
    },
    [signup.fulfilled]: (state, action) => {
      state.signUpLogs.isSuccess = true;
      state.signUpLogs.isLoading = false;
    },
    [signup.pending]: (state) => {
      state.signUpLogs.error = '';
      state.signUpLogs.isFailed = false;
      state.signUpLogs.isLoading = true;
      state.signUpLogs.isSuccess = false;
    },
    [signup.rejected]: (state, action) => {
      state.signUpLogs.isFailed = true;
      state.signUpLogs.isLoading = false;
      state.signUpLogs.error = action?.payload?.errors[0]?.detail;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.forgotPassLogs.isSuccess = true;
      state.forgotPassLogs.isLoading = false;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPassLogs.error = '';
      state.forgotPassLogs.isFailed = false;
      state.forgotPassLogs.isLoading = true;
      state.forgotPassLogs.isSuccess = false;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgotPassLogs.isFailed = true;
      state.forgotPassLogs.isLoading = false;
      state.forgotPassLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.updatePasswordLogs.isSuccess = true;
      state.updatePasswordLogs.isLoading = false;
    },
    [updatePassword.pending]: (state) => {
      state.updatePasswordLogs.error = '';
      state.updatePasswordLogs.isFailed = false;
      state.updatePasswordLogs.isLoading = true;
      state.updatePasswordLogs.isSuccess = false;
    },
    [updatePassword.rejected]: (state, action) => {
      state.updatePasswordLogs.isFailed = true;
      state.updatePasswordLogs.isLoading = false;

      if (action?.payload && action?.payload?.errors) {
        state.updatePasswordLogs.error = action?.payload?.errors[0]?.detail;
      } else {
        state.updatePasswordLogs.error = action?.payload?.message;
      }
    },
  },
});

export const { actions: authActions, reducer: authReducer } = slice;

export const { resetForgotState, logout, setIsAuthenticated, resetSignUpState, updateUserinfo } =
  authActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.auth || initialState;

export const selectAuth = createSelector([selectDomain], (authState) => authState);

/* --------------------------------------------------------------------------------- */

export { login, signup, forgotPassword, updatePassword, updateProfileData };
