/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import { getUsersState } from 'reducers/usersReducer';
import TablePagination from 'components/GlobalUIs/Pagination';
import SamplePagination from 'components/GlobalUIs/SamplePagination';

import styles from './AccessControlList.module.scss';

const AccessControlList = ({ data, setFilter, handlePaginationClick }) => {
  const userState = useSelector(getUsersState);
  const { logs, meta } = userState;

  return (
    <Table responsive className={styles?.inventoryTable}>
      <thead>
        <tr>
          <th>User ID</th>
          <th>Account Name</th>
          <th>User Role</th>
          <th>Date</th>
          <th>Time</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length >= 1 &&
          data.map((dd) => (
            <tr key={dd?.id}>
              <td>{dd?.userID}</td>
              <td>{dd?.accountName}</td>
              <td>{dd?.userRole}</td>
              <td>{dd?.date}</td>
              <td>{dd?.time}</td>
              <td>
                <span className={styles[`${dd?.status}`]}>{dd?.status}</span>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={10}>
            <TablePagination
              meta={meta}
              setParams={setFilter}
              loading={logs?.isLoading}
              handlePaginationClick={handlePaginationClick}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default AccessControlList;
