/* eslint-disable multiline-comment-style */
import React from 'react';
import { Pagination } from 'react-bootstrap';

import '../Styles/TablePagination.scss';

const TablePagination = ({ meta, loading, setParams, handlePaginationClick }) => {
  if (!meta)
    return (
      <div className='table-pagination'>
        <p>Loading...</p>
      </div>
    );

  const { pagination } = meta;

  const handleCurrentPage = () => {
    if (!pagination) return '';

    return (
      <p>
        Showing page {pagination?.current_page} of {pagination?.total_pages}
      </p>
    );
  };

  const handlePageChange = (pp) => {
    setParams((prev) => {
      return {
        ...prev,
        page: pp,
      };
    });

    handlePaginationClick({
      page: pp,
    });
  };

  const canPreviousPage = pagination?.current_page !== 1;
  const canNextPage = pagination?.current_page !== pagination?.total_pages;

  return (
    <div className='table-pagination'>
      {handleCurrentPage()}

      <Pagination>
        <Pagination.First disabled={loading} onClick={() => handlePageChange(1)} className='box' />

        <Pagination.Prev
          className='box'
          disabled={loading || !canPreviousPage}
          onClick={() => handlePageChange(parseInt(pagination?.current_page) - 1)}
        />

        {canPreviousPage && (
          <Pagination.Item
            disabled={loading}
            onClick={() => handlePageChange(parseInt(pagination?.current_page) - 1)}
          >
            {pagination?.current_page - 1}
          </Pagination.Item>
        )}

        <Pagination.Item active>{pagination?.current_page}</Pagination.Item>

        {canNextPage && (
          <Pagination.Item
            disabled={loading}
            onClick={() => handlePageChange(parseInt(pagination?.current_page) + 1)}
          >
            {pagination?.current_page + 1}
          </Pagination.Item>
        )}

        <Pagination.Next
          disabled={loading || !canNextPage}
          onClick={() => handlePageChange(parseInt(pagination?.current_page) + 1)}
          className='box'
        />

        <Pagination.Last
          className='box'
          disabled={loading}
          onClick={() => handlePageChange(pagination?.total_pages)}
        />
      </Pagination>
    </div>
  );
};

export default TablePagination;
