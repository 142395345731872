import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import styles from './index.module.scss';

const index = (props) => {
  return (
    <Modal
      size='md'
      centered
      {...props}
      onHide={props.onHide}
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header} closeButton></Modal.Header>

      <Modal.Body className={styles?.body}>
        <p>Registration Approval</p>

        <Button className={styles?.close} onClick={props.onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default index;
