/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Components
import AddRoles from './components/AddRoles';
import UserRoles from './components/UserRoles';
import { getUsers } from 'reducers/usersReducer';
import { objectCleaner } from 'utils/objectCleaner';
import UserRolesFilter from './components/UserRolesFilter';
import ConditionalRender from 'components/ConditionalRender';
import AccessControlList from './components/AccessControlList';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Assets
import styles from './index.module.scss';
import samplePicture from 'assets/images/bondpaper.png';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as ListSVG } from 'assets/svg/list.svg';
import { ReactComponent as CirclePlus } from 'assets/svg/circle plus.svg';

// Main component
const Acl = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const [selectTab, setSelectTab] = useState('Access Control List');

  const [data, setData] = useState([]);
  const [refreshRole, setRefreshRole] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    sort_by: '',
    filter_status: 'approved',
  });

  const [showAdd, setShowAdd] = useState(false);

  // For ACL
  const tryCatch = async (params) => {
    setData([]);

    try {
      const response = await dispatch(getUsers(params));

      const newData = response?.payload?.data?.map((dd) => {
        const dateTimeString = dd?.attributes?.created_at;
        const dateTime = new Date(dateTimeString);

        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        const dateString = dateTime.toLocaleDateString('en-US', options);
        const timeString = dateTime.toLocaleTimeString();

        return {
          ...dd,
          id: dd?.id,
          userID: dd?.id,
          date: dateString,
          time: timeString,
          userRole: dd?.attributes?.role,
          status: dd?.attributes?.status,
          accountName: dd?.attributes?.full_name,
        };
      });

      setData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  // For ACL
  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        q: value,
        sort_by: filter?.sort_by,
        filter_status: 'approved',
        per_page: filter?.per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  // For ACL
  const handleSort = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value, page: 1 }));

    const newObj = {
      page: 1,
      sort_by: value,
      q: filter?.search,
      per_page: filter?.per_page,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  // For ACL
  const handleUsers = () => {
    const unfinalParams = {
      q: filter?.search,
      page: filter?.page,
      sort_by: filter?.sort_by,
      per_page: filter?.per_page,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  // For ACL
  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: filter?.search,
      sort_by: filter?.sort_by,
      filter_status: filter?.filter_status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  useEffect(() => {
    handleUsers();
  }, []);

  return (
    <PrivateLayout pageTitle='ACL'>
      <div className={styles?.Acl}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => setSelectTab('Access Control List')}
                        className={`${selectTab === 'Access Control List' ? styles?.active : ''}`}
                      >
                        Access Control List
                      </Button>
                    </div>
                    <div className={styles?.buttonContainer}>
                      <Button
                        onClick={() => setSelectTab('User Roles')}
                        className={`${selectTab === 'User Roles' ? styles?.active : ''}`}
                      >
                        User Roles
                      </Button>
                    </div>
                  </div>

                  <div className={styles?.filter}>
                    {/* For ACL */}
                    {selectTab === 'Access Control List' && (
                      <>
                        <Form.Group className={styles?.searchBar}>
                          <GlassSVG />
                          <FormControl
                            name='search'
                            placeholder='Search'
                            value={filter?.search}
                            onChange={handleSearch}
                          />
                        </Form.Group>

                        <div className={styles?.selectContainer}>
                          <Form.Select
                            name='sort_by'
                            onChange={handleSort}
                            className={styles?.select}
                            value={filter?.sort_by}
                          >
                            <option value='' hidden>
                              Sort By
                            </option>
                            <option value='ASC'>Ascending</option>
                            <option value='DESC'>Descending</option>
                          </Form.Select>
                        </div>
                      </>
                    )}

                    {selectTab === 'User Roles' && <UserRolesFilter />}

                    <Button
                      className={styles?.processItem}
                      onClick={() => {
                        setShowAdd(true);
                      }}
                    >
                      <CirclePlus />
                      Create Roles
                    </Button>
                  </div>
                </div>

                <div className={styles?.body}>
                  <ConditionalRender
                    condition={selectTab === 'Access Control List'}
                    renderIf={
                      <AccessControlList
                        data={data}
                        setFilter={setFilter}
                        handleUsers={handleUsers}
                        handlePaginationClick={handlePaginationClick}
                      />
                    }
                  />

                  <ConditionalRender
                    condition={selectTab === 'User Roles'}
                    renderIf={
                      <UserRoles refreshRole={refreshRole} setRefreshRole={setRefreshRole} />
                    }
                  />
                </div>
              </div>

              <AddRoles setRefreshRole={setRefreshRole} show={showAdd} setShowAdd={setShowAdd} />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default Acl;
