// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { Form, Image } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import ConditionalRender from 'components/ConditionalRender';
import { handleUploadChange } from 'helpers/handleUploadChange';

// Assets
import '../Styles/ImageUpload.scss';
import { ReactComponent as UploadSVG } from 'assets/svg/upload.svg';

// Main component
const ImageUpload = ({ name, setState, stateValue, ...rest }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeVideo = (e) => {
    if (e.target.files.length > 0) {
      toast.dismiss();
      toast.info('Please click the picture to upload different file');
    }

    handleUploadChange(setState)(e);
  };

  const showVideoPreview = () => {
    if (stateValue && typeof stateValue[0] === 'string') {
      return <Image src={stateValue} />;
    }

    if (stateValue[0] && typeof stateValue[0] === 'string') {
      return <Image src={stateValue[0]} />;
    }

    if (stateValue[0] && stateValue) {
      const sampleURL = URL.createObjectURL(stateValue[0]);

      return <Image src={sampleURL} />;
    }
  };

  return (
    <Form.Group className='thumbnail-upload'>
      <ConditionalRender
        condition={stateValue.length > 0}
        renderIf={
          <Form.Label onClick={handleClick} htmlFor={camelize(name)} className='thumbnail-preview'>
            {showVideoPreview()}
          </Form.Label>
        }
        renderElse={
          <Form.Label
            onClick={handleClick}
            htmlFor={camelize(name)}
            className='upload-thumbnail-preview'
          >
            <div className='center-data'>
              <UploadSVG />
              Upload Image
            </div>
          </Form.Label>
        }
      />

      <Form.Control
        {...rest}
        type='file'
        ref={hiddenFileInput}
        name={camelize(name)}
        onChange={handleChangeVideo}
        accept='image/png, image/gif, image/jpeg'
      />
    </Form.Group>
  );
};

export default ImageUpload;
