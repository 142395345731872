/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getRequest, approveRequest } from './thunks/requestThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  users: [],
  meta: null,
  logs: initialLog,
  addLogs: initialLog,
  requestLogs: initialLog,

  approveLogs: initialLog,
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {},
  extraReducers: {
    [getRequest.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.users = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getRequest.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getRequest.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [approveRequest.fulfilled]: (state, action) => {
      state.approveLogs.isSuccess = true;
      state.approveLogs.isLoading = false;
    },
    [approveRequest.pending]: (state) => {
      state.approveLogs.error = '';
      state.approveLogs.isFailed = false;
      state.approveLogs.isLoading = true;
      state.approveLogs.isSuccess = false;
    },
    [approveRequest.rejected]: (state, action) => {
      state.approveLogs.isFailed = true;
      state.approveLogs.isLoading = false;

      if (action?.payload?.message?.detail) {
        state.approveLogs.error = action?.payload?.message?.detail;
      } else {
        state.approveLogs.error = action?.payload?.errors[0]?.detail;
      }
    },
  },
});

export const { actions: requestActions, reducer: requestReducer } = slice;

export const { updateUserinfo } = requestActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.request || initialState;

export const selectRequest = createSelector([selectDomain], (requestState) => requestState);

/* --------------------------------------------------------------------------------- */

export { getRequest, approveRequest };
