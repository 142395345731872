import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import setAuthToken from 'services/setAuthToken';
import { selectAuth } from 'reducers/authReducer';

// Public Routes
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
// import PageNotFound from 'pages/404';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';

// Private Routes
import Acl from 'pages/Acl';
import Staff from 'pages/Staff';
import Users from 'pages/Users';
import Reports from 'pages/Reports';
import ViewAcl from 'pages/ViewAcl';
import Approver from 'pages/Approver';
import ViewItem from 'pages/ViewItem';
import Inventory from 'pages/Inventory';
import Dashboard from 'pages/Dashboard';
import AuditTrail from 'pages/AuditTrail';
import Transaction from 'pages/Transaction';
import UserAccount from 'pages/UserAccount';
import ItemRequest from 'pages/ItemRequest';
import UserPassword from 'pages/UserPassword';

// Components
import ProtectedRoute from './ProtectedRoute';

const SystemRoutes = () => {
  const loginStoreState = useSelector(selectAuth);
  const { token, isAuthenticated } = loginStoreState;

  setAuthToken(token);

  return isAuthenticated ? (
    // Private Routes
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/user-account' element={<UserAccount />} />
      <Route path='/change-password' element={<UserPassword />} />
      <Route path='/acl' element={<ProtectedRoute page={'ACL'} component={Acl} />} />
      <Route path='/users' element={<ProtectedRoute page={'Users'} component={Users} />} />
      <Route path='/acl/:id' element={<ProtectedRoute page={'ACL'} component={ViewAcl} />} />
      <Route path='/reports' element={<ProtectedRoute page={'Reports'} component={Reports} />} />
      <Route path='/approver' element={<ProtectedRoute page={'Approver'} component={Approver} />} />
      <Route
        path='/inventory'
        element={<ProtectedRoute page={'Inventory'} component={Inventory} />}
      />
      <Route
        path='/inventory-staff'
        element={<ProtectedRoute page={'Inventory Staff'} component={Staff} />}
      />
      <Route
        path='/audit-trail'
        element={<ProtectedRoute page={'Audit Trail'} component={AuditTrail} />}
      />
      <Route
        path='/inventory/:id'
        element={<ProtectedRoute page={'Inventory'} component={ViewItem} />}
      />
      <Route
        path='/transactions'
        element={<ProtectedRoute page={'Transaction'} component={Transaction} />}
      />
      <Route
        path='/item-request'
        element={<ProtectedRoute page={'Item Request'} component={ItemRequest} />}
      />
      <Route path='*' element={<Dashboard />} />
    </Routes>
  ) : (
    // Public Routes
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/password-reset' element={<UpdatePassword />} />
      <Route path='/account-recovery' element={<ForgotPassword />} />

      {/* When Route not found will redirect to login page */}
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default SystemRoutes;
