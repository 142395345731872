/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { addApprover, deleteApprover, getApprovers, updateApprover } from './thunks/approverThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  approvers: [],
  meta: null,
  logs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
  addLogs: initialLog,
};

const slice = createSlice({
  name: 'approver',
  initialState,
  reducers: {},
  extraReducers: {
    [getApprovers.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.approvers = action.payload.data.data;
      state.meta = action.payload.data.meta;
    },
    [getApprovers.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getApprovers.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [updateApprover.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateApprover.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateApprover.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deleteApprover.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteApprover.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deleteApprover.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    },
    [addApprover.fulfilled]: (state, action) => {
      state.addLogs.isSuccess = true;
      state.addLogs.isLoading = false;
    },
    [addApprover.pending]: (state) => {
      state.addLogs.error = '';
      state.addLogs.isFailed = false;
      state.addLogs.isLoading = true;
      state.addLogs.isSuccess = false;
    },
    [addApprover.rejected]: (state, action) => {
      state.addLogs.isFailed = true;
      state.addLogs.isLoading = false;
      state.addLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: approverActions, reducer: approverReducer } = slice;

export const { updateUserinfo } = approverActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.approver || initialState;

export const selectApprover = createSelector([selectDomain], (approverState) => approverState);

/* --------------------------------------------------------------------------------- */

export { addApprover, deleteApprover, getApprovers, updateApprover };
