import React, { useState } from 'react';

import { Image } from 'react-bootstrap';

const ImageUI = ({ src, alt, fallbackSrc, ...rest }) => {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return error ? (
    <Image src={fallbackSrc} alt={alt} {...rest} />
  ) : (
    <Image src={src} alt={alt} onError={handleError} {...rest} />
  );
};

export default ImageUI;
