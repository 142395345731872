/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, FormControl } from 'react-bootstrap';

import SelectUser from './SelectUser';
import { checkEmpty } from 'utils/validator';
import FormNumber from 'components/GlobalUIs/FormNumber';
import ImageUpload from 'components/GlobalUIs/ImageUpload';
import { selectInventory, requestItem } from 'reducers/inventoryReducer';

import styles from './RequestItem.module.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/minusSign.svg';
import { ReactComponent as PlusSVG } from 'assets/svg/plusSign.svg';

const RequestItem = ({ show = false, type, setShowRequest, handleInventory }) => {
  const dispatch = useDispatch();

  const inventoryState = useSelector(selectInventory);
  const { requestLogs } = inventoryState;

  const [data, setData] = useState({
    warehouses: [],
    reason: '',
    quantity: 0,
    itemName: '',
    requestFor: '',
    transaction: '',
    availableStock: '',
  });

  const [options, setOptions] = useState([]);

  const handleSave = async () => {
    let emptyCheck = true;

    if (type === 'others') {
      emptyCheck = checkEmpty(data, ['warehouses']);
    } else {
      emptyCheck = checkEmpty(data, ['warehouses', 'requestFor']);
    }

    if (emptyCheck) {
      toast.error('Please fill in the empty fields');
      return;
    }

    const formData = new FormData();

    formData.append('quantity', data?.quantity);
    formData.append('item_name', data?.itemName);
    formData.append('description', data?.reason);
    formData.append('transaction_type', data?.transaction);

    if (type === 'others') {
      formData.append('borrower', data?.requestFor?.value);
    }

    try {
      const response = await dispatch(requestItem(formData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        handleClose();
        handleInventory(); // refresh data
        toast.info('Successfully Requested Item');
      }

      setData((prev) => ({ ...prev, type: '' }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClose = () => {
    setShowRequest({
      show: false,
      type: '',
    });

    setData((prev) => ({
      ...prev,
      reason: '',
      quantity: 0,
      itemName: '',
      requestFor: '',
      transaction: '',
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchItem = (e) => {
    console.log(e);

    setData((prev) => {
      return {
        ...prev,
        itemName: e?.label,
        availableStock: e?.attributes?.total_stocks,
      };
    });
  };

  const handleUserSelect = (e) => {
    setData((prev) => {
      return {
        ...prev,
        requestFor: e,
      };
    });
  };

  const handleMinus = () => {
    const quantity = data?.quantity;

    if (quantity <= 0) return;

    const newQuantity = +quantity - 1;

    setData((prev) => ({
      ...prev,
      quantity: newQuantity,
    }));
  };

  const handlePlus = () => {
    const quantity = data?.quantity;

    const newQuantity = +quantity + 1;

    setData((prev) => ({
      ...prev,
      quantity: newQuantity,
    }));
  };

  useEffect(() => {
    const getItems = async () => {
      try {
        axios.get('api/inventory').then((response) => {
          const newData = response?.data?.data.map((dd) => {
            return {
              ...dd,
              value: dd?.attributes?.item_name,
              label: dd?.attributes?.item_name,
            };
          });

          setOptions(newData);
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    const getStorage = async () => {
      try {
        axios.get('api/warehouse').then((response) => {
          setData((prev) => ({ ...prev, warehouses: response?.data?.data }));
        });
      } catch (error) {
        console.log('Error', error);
      }
    };

    getStorage();
    getItems();
  }, []);

  return (
    <div className={`${styles?.addItem} ${show ? styles?.active : ''}`}>
      <div className={styles?.header}>
        <p>Request Item</p>

        <CloseSVG className={styles?.close} onClick={handleClose} />
      </div>

      <div className={styles?.body}>
        <Form.Group className={styles?.searchBar}>
          <Form.Label className={styles?.search}>Search Item</Form.Label>

          <Select
            options={options}
            styles={stylesOptions}
            value={data?.itemName}
            onChange={handleSearchItem}
            placeholder='Search for code, name...'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Item Name</Form.Label>
          <FormControl
            readOnly
            name='itemName'
            value={data?.itemName}
            onChange={handleChange}
            placeholder='Product name here'
          />
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Quantity Needed</Form.Label>
          <div className={styles?.inline}>
            <FormNumber
              readOnly
              name='quantity'
              placeholder='0'
              value={data?.quantity}
              onChange={handleChange}
              className={styles?.small}
            />

            <div className={styles?.actions}>
              <MinusSVG onClick={handleMinus} />
              <PlusSVG onClick={handlePlus} />
            </div>

            {data?.availableStock && (
              <div className={styles?.stock}>
                <p>Available Stock: {data?.availableStock}</p>
              </div>
            )}
          </div>
        </Form.Group>

        <Form.Group className={styles?.group}>
          <Form.Label>Type of Transaction</Form.Label>
          <Form.Select
            name='transaction'
            onChange={handleChange}
            placeholder='Enter here'
            value={data?.transaction}
            className={styles?.transaction}
          >
            <option value='' hidden>
              Select Type
            </option>
            <option value='BORROWED ITEM'>Borrow Item</option>
            <option value='RETURN ITEM'>Return Item</option>
          </Form.Select>
        </Form.Group>

        {type && type === 'others' && (
          <Form.Group className={styles?.searchBar}>
            <Form.Label className={styles?.search}>Request item for</Form.Label>

            <SelectUser data={data?.requestFor} onChange={handleUserSelect} />
          </Form.Group>
        )}

        <Form.Group className={styles?.group}>
          <Form.Label>Reason</Form.Label>

          <Form.Control
            rows={5}
            as='textarea'
            name='reason'
            value={data?.reason}
            onChange={handleChange}
            placeholder='Reason'
          />
        </Form.Group>

        <Button
          onClick={handleSave}
          disabled={requestLogs?.isLoading}
          className={styles?.processButton}
        >
          Request Item
        </Button>
      </div>
    </div>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    height: '38px',
    fontWeight: 400,
    fontSize: '10px',
    borderRadius: '19px',
    background: '#F3F3F3',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '10px',
    background: '#F3F3F3',
  }),
};

export default RequestItem;
