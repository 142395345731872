/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const sampleGetAPI = createAsyncThunk(
  'sampleGetAPI',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `sample-url`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const samplePostAPI = createAsyncThunk(
  'samplePostAPI',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `sample-url`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const samplePutAPI = createAsyncThunk(
  'samplePutAPI',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'put',
      url: `sample-url`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const sampleDeleteAPI = createAsyncThunk(
  'sampleDeleteAPI',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'delete',
      url: `sample-url/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
