/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Dropdown, Button } from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUIs/ImageUI';
import ShowUser from './components/Modals/ShowUser/ShowUser';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getUsersState, updateUser, deleteUser } from 'reducers/usersReducer';
import ConfirmModal from './components/Modals/Confirm/ConfirmModal';

// Assets
import styles from './UsersTab.module.scss';
import placeholderProfile from 'assets/images/avatar.jpg';

// Main component
const UsersTab = ({ setFilter, canUpdate, data, handleUsers, handlePaginationClick }) => {
  const dispatch = useDispatch();

  const usersDataList = useSelector(getUsersState);
  const { meta, logs } = usersDataList;

  const [showView, setShowView] = useState({
    show: false,
    data: null,
  });
  const [showConfirm, setShowConfirm] = useState({
    data: '',
    title: '',
    show: false,
    active: null,
  });

  const handleCloseUpdate = () => {
    setShowConfirm({
      data: '',
      title: '',
      show: false,
      active: null,
    });
  };

  const showDeactivateModal = (dd) => {
    setShowConfirm({
      data: dd,
      show: true,
      active: 'inactive',
      title: 'Deactivate account?',
    });
  };

  const showActivateModal = (dd) => {
    setShowConfirm({
      data: dd,
      show: true,
      active: 'active',
      title: 'Re-activate account?',
    });
  };

  const showDeleteModal = (dd) => {
    setShowConfirm({
      data: dd,
      show: true,
      active: 'delete',
      title: 'Delete account?',
    });
  };

  const handleSubmit = async () => {
    if (showConfirm?.active === 'delete') {
      try {
        const response = await dispatch(deleteUser(showConfirm?.data?.id));

        if (response) {
          handleUsers();
          toast.info(`Successfully Deleted User`);
          handleCloseUpdate();
        }
      } catch (error) {
        toast.error(error);
      }

      return;
    }

    const objData = {
      id: showConfirm?.data?.id,
      params: { status: showConfirm?.active },
    };

    try {
      const response = await dispatch(updateUser(objData));

      if (response) {
        handleUsers();
        toast.info(
          `Successfully ${showConfirm?.active === 'active' ? 'Activated' : 'Deactivated'} User`,
        );
        handleCloseUpdate();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const showData = (dd) => {
    setShowView({
      show: true,
      data: dd,
    });
  };

  return (
    <>
      <Table responsive className={styles?.processTable}>
        <thead>
          <tr>
            <th>Image</th>
            <th>User ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Date Added</th>
            <th>Position</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length >= 1 &&
            data.map((dd) => (
              <tr key={dd?.id}>
                <td
                  className={styles?.clickable}
                  onClick={() => {
                    if (canUpdate) {
                      showData(dd);
                    }
                  }}
                >
                  <ImageUI src={dd?.image} alt={dd?.name} fallbackSrc={placeholderProfile} />
                </td>
                <td>{dd?.userID}</td>
                <td
                  className={styles?.clickable}
                  onClick={() => {
                    if (canUpdate) {
                      showData(dd);
                    }
                  }}
                >
                  {dd?.name}
                </td>
                <td>{dd?.email}</td>
                <td>{dd?.dateAdded}</td>
                <td>{dd?.position}</td>

                <td>
                  <span
                    className={styles[`${dd?.status === 'active' ? 'Available' : 'NotAvailable'}`]}
                  >
                    {dd?.status}
                  </span>
                </td>
                <td>
                  <div className={styles?.buttonContainer}>
                    {dd?.status === 'active' && (
                      <Button
                        onClick={() => showDeactivateModal(dd)}
                        className={styles?.deactivate}
                      >
                        Deactivate
                      </Button>
                    )}

                    {dd?.status === 'inactive' && (
                      <>
                        <Button onClick={() => showActivateModal(dd)} className={styles?.activate}>
                          Activate
                        </Button>

                        <Button onClick={() => showDeleteModal(dd)} className={styles?.delete}>
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={10}>
              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </td>
          </tr>
        </tfoot>
      </Table>

      <ConfirmModal
        show={showConfirm?.show}
        handleUsers={handleUsers}
        title={showConfirm?.title}
        handleConfirm={handleSubmit}
        handleClose={handleCloseUpdate}
      />

      <ShowUser
        show={showView?.show}
        viewData={showView?.data}
        handleUsers={handleUsers}
        setShowView={setShowView}
      />
    </>
  );
};

export default UsersTab;
