/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

import RolesModal from './Modals/Roles/RolesModal';
import { objectCleaner } from 'utils/objectCleaner';
import DeleteModal from './Modals/Delete/DeleteModal';
import AssignModal from './Modals/AssignModal/AssignModal';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getRoles, selectRoles, updateFilter } from 'reducers/rolesReducer';

import styles from './UserRoles.module.scss';

const UserRoles = ({ setRefreshRole, refreshRole }) => {
  const dispatch = useDispatch();

  const rolesState = useSelector(selectRoles);
  const { roles: data, meta, logs } = rolesState;
  const { q, page, per_page, sort_by } = rolesState.filterState;

  const [showUpdateModal, setShowUpdateModal] = useState({
    show: false,
    data: null,
  });

  const [showAssignModal, setShowAssignModal] = useState({
    show: false,
    data: null,
  });

  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    data: null,
  });

  const handleCloseUpdate = () => {
    setShowUpdateModal((prev) => ({ ...prev, show: false, data: null }));
  };

  const handleCloseAssign = () => {
    setShowAssignModal((prev) => ({ ...prev, show: false, data: null }));
  };

  const handleCloseDelete = () => {
    setShowDeleteModal((prev) => ({ ...prev, show: false, data: null }));
  };

  const handleShowRoles = (viewData) => {
    setShowUpdateModal((prev) => {
      return {
        ...prev,
        data: viewData,
        show: true,
      };
    });
  };

  const handleShowAssign = (viewData) => {
    setShowAssignModal((prev) => {
      return {
        ...prev,
        data: viewData,
        show: true,
      };
    });
  };

  const handleShowDelete = (viewData) => {
    setShowDeleteModal((prev) => {
      return {
        ...prev,
        data: viewData,
        show: true,
      };
    });
  };

  const tryCatch = (params, defaultParams) => {
    try {
      dispatch(getRoles(params));
      dispatch(updateFilter(defaultParams));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleRoles = () => {
    const unfinalParams = {
      q: q,
      page: page,
      sort_by: sort_by,
      per_page: per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      q: q,
      page: page,
      sort_by: sort_by,
      per_page: per_page,
    };

    dispatch(updateFilter({ page: page }));

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  useEffect(() => {
    handleRoles();
  }, []);

  useEffect(() => {
    if (refreshRole) {
      handleRoles();
      setRefreshRole(false);
    }
  }, [refreshRole]);

  return (
    <>
      <Table responsive className={styles?.userRoles}>
        <thead>
          <tr>
            <th>User Roles</th>
            <th>Date Created</th>
            <th>Added By</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length >= 1 &&
            data.map((dd) => {
              const dateTimeString = dd?.attributes?.created_at;
              const dateTime = new Date(dateTimeString);

              const options = { month: 'short', day: '2-digit', year: 'numeric' };
              const dateString = dateTime.toLocaleDateString('en-US', options);

              return (
                <tr key={dd?.id}>
                  <td>{dd?.attributes?.name}</td>
                  <td>{dateString}</td>
                  <td>{dd?.attributes?.added_by}</td>
                  <td>
                    <div className={styles?.tableActions}>
                      <Button onClick={() => handleShowRoles(dd)} className={styles?.update}>
                        Update
                      </Button>
                      <Button
                        onClick={() => handleShowAssign(dd)}
                        className={styles?.assignPermission}
                      >
                        Assign Permission
                      </Button>
                      <Button onClick={() => handleShowDelete(dd)} className={styles?.delete}>
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={10}>
              <TablePagination
                meta={meta}
                setParams={() => {}}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </td>
          </tr>
        </tfoot>
      </Table>

      <RolesModal
        handleRoles={handleRoles}
        data={showUpdateModal?.data}
        show={showUpdateModal?.show}
        handleClose={handleCloseUpdate}
      />

      <AssignModal
        handleRoles={handleRoles}
        data={showAssignModal?.data}
        show={showAssignModal?.show}
        editData={setShowAssignModal}
        setRefreshRole={setRefreshRole}
        handleClose={handleCloseAssign}
      />

      <DeleteModal
        handleRoles={handleRoles}
        data={showDeleteModal?.data}
        show={showDeleteModal?.show}
        handleClose={handleCloseDelete}
      />
    </>
  );
};

export default UserRoles;
