/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getInventory = createAsyncThunk(
  'getInventory',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `/api/inventory`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const addItem = createAsyncThunk('addItem', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `/api/inventory`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const requestItem = createAsyncThunk('requestItem', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `/api/transaction`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getItemDetails = createAsyncThunk(
  'getItemDetails',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `/api/inventory/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
