/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Form, Button, Image, Container, Row, Col, FormControl, Table } from 'react-bootstrap';

// Components
import AddProcess from './components/AddProcess';
import TablePagination from 'components/GlobalUIs/Pagination';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import SamplePagination from 'components/GlobalUIs/SamplePagination';

// Assets
import styles from './index.module.scss';
import samplePicture from 'assets/images/bondpaper.png';
import { ReactComponent as ListSVG } from 'assets/svg/list.svg';
import { ReactComponent as GlassSVG } from 'assets/svg/glass.svg';
import { ReactComponent as PrintSVG } from 'assets/svg/print.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow left.svg';
import { ReactComponent as DownloadSVG } from 'assets/svg/download.svg';

// Main component
const ViewAcl = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    details: {
      id: 1,
      userID: 'User001',
      accountName: 'Juan Dela Cruz',
      userRole: 'Admin',
      date: '05/22/2023',
      time: '09:00 a.m.',
      status: 'Active',
      permissions: [
        'Add Product to inventory',
        'Delete Product to inventory',
        'Edit Product to inventory',
      ],
    },
    tableData: [
      {
        id: 1,
        permission: 'Add Product to inventory',
      },
      {
        id: 2,
        permission: 'Delete Product to inventory',
      },
      {
        id: 3,
        permission: 'Edit Product to inventory',
      },
      {
        id: 4,
        permission: 'Add User to system',
      },
    ],
  });

  const [filter, setFilter] = useState({
    search: '',
  });

  const [showAdd, setShowAdd] = useState(false);

  const handleFilter = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const checkedPermission = (tableData) => {
    if (!data?.details?.permissions) return;

    const permissions = data?.details?.permissions;
    const result = permissions.filter((dd) => dd === tableData);

    console.log('yawaaa', result);

    if (result.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <PrivateLayout pageTitle='View Item'>
      <div className={styles?.inventory}>
        <Container>
          <Row>
            <Col className={styles?.columm}>
              <Link className={styles?.back} to={'/acl'}>
                <ArrowSVG />
                Back to ACL
              </Link>

              <div className={styles?.profileContainer}>
                <div className={styles?.header}>
                  <div className={styles?.search}>
                    <h5>{data?.details?.accountName}</h5>

                    <div className={styles?.accounts}>
                      <span className={`${styles?.role} ${styles[`${data?.details?.userRole}`]}`}>
                        {data?.details?.userRole}
                      </span>

                      <span className={`${styles?.button} ${styles[`${data?.details?.status}`]}`}>
                        {data?.details?.status}
                      </span>
                    </div>
                  </div>

                  <div className={styles?.filter}>
                    <Form.Group className={styles?.searchBar}>
                      <GlassSVG />
                      <FormControl
                        name='search'
                        placeholder='Search'
                        value={filter?.search}
                        onChange={handleFilter}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className={styles?.body}>
                  <Table responsive className={styles?.processTable}>
                    <thead>
                      <tr>
                        <th>System</th>
                        <th>Access</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.tableData &&
                        data?.tableData?.length >= 1 &&
                        data?.tableData.map((dd) => (
                          <tr key={dd?.id}>
                            <td>{dd?.permission}</td>
                            <td>
                              <Form.Check
                                type='switch'
                                className={styles?.switch}
                                checked={checkedPermission(dd?.permission)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <SamplePagination />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>

              <AddProcess show={showAdd} setShowAdd={setShowAdd} />
            </Col>
          </Row>
        </Container>
      </div>
    </PrivateLayout>
  );
};

export default ViewAcl;
